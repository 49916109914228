import { Button, Card, Divider, Row, Space } from "@sellevate-team/ui-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function NotFound({ type }) {
  const { t } = useTranslation();

  let content = false;
  if (type === "home") {
    content = <>
      <div>{t("Use the button below to go to the dashboard page.")}</div>
      <Link to={"/"} ><Button >{t("Dashboard")}</Button></Link>
    </>;
  } else if (type === "product") {
    content = <>
      <div>{t("Use the button below to go back to products.")}</div>
      <Link to={"../products"} ><Button >{t("Products")}</Button></Link>
    </>;
  } else if (type === "order") {
    content = <>
      <div>{t("Use the button below to go back to orders.")}</div>
      <Link to={"../orders"} ><Button >{t("Orders")}</Button></Link>
    </>;
  } else if (type === "return") {
    content = <>
      <div>{t("Use the button below to go back to returns.")}</div>
      <Link to={"../returns"} ><Button >{t("Returns")}</Button></Link>
    </>;
  }

  return (
    <Row justify={"space-around"}>
      <Card title={t("404. Not found.")}>
        <div>{t("The page you were looking for doesn't exist.")}</div>
        {content && <>
          <Divider />
          <Space direction="vertical">
            {content}
          </Space>
        </>}
      </Card>
    </Row>
  );
}

NotFound.propTypes = {
  type: PropTypes.oneOf(["home", "product", "order", "return"])
};

export default NotFound;


