import { NotificationCarousel } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";

export function ProductDetailHelpDescription({ ...props }) {
  const { t } = useTranslation("help", { keyPrefix: "productDetail" });
  return (
    <NotificationCarousel highlights={["product-detail-statistics", "product-detail-history-chart", "product-detail-history-chart", "product-detail-category-tree", "product-detail-side"]} {...props}>
      <div>
        {t("The statistics blocks on the product page give you direct insight into the performance of your product.")}
      </div>
      <div>
        {t("Analyze the history of your product in the chart. Here it is possible to view different values over time.")}
      </div>
      <div>
        {t("Use the buttons in the top right corner of the graph to change the graph settings.")}
      </div>
      <div>
        {t("The category tree displays the categories the product is in.")}
      </div>
      <div>
        {t("On the right you will find the product photo, the specifications and the description of your product.")}
      </div>
    </NotificationCarousel>
  );
}
