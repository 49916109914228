import { useDynamicNavigation } from "@sellevate-team/ui-components";
import { useLayoutEffect } from "react";
import { useMatch, useResolvedPath, useLocation, matchRoutes, useParams } from "react-router-dom";

import { useRoutes } from "../routes.js";

export function useRelativeMatch(to, end = true) {
  const resolved = useResolvedPath(to);
  return useMatch({ path: resolved.pathname, end: end });
}


export function useDynamicPathLabel(label, to = "") {
  const resolved = useResolvedPath(to);
  const currentPath = resolved.pathname.split("/").slice(2).filter(p => p != "").join("/");
  const {
    addDynamicPathLabel,
  } = useDynamicNavigation();

  useLayoutEffect(() => {
    if (label === undefined) {
      return;
    }
    addDynamicPathLabel(currentPath, label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);
}

export function useMatchingRoute() {
  const location = useLocation();
  const routes = useRoutes();
  const { storeIds } = useParams();

  const pathname = "/" + location.pathname.split("/").slice(2).join("/");

  const process = route => ({
    ...route,
    children: route.routes?.map(process),
  });

  // Map the routes to a new object where the path and children are replaced recursively using `process`
  const newRoutes = routes.map(process);

  // Match the new routes with the current location
  const routeMatches = matchRoutes(newRoutes, location, `/${storeIds}`);

  // Define a method for computing the common prefix length between two strings
  const commonPrefixCount = (x, y) => [...x].map((c, i) => c === y[i]).reduce((v, r) => v + r, 0);

  // Find the route match with the longest common prefix
  const match = routeMatches?.map(match => ({
    ...match,
    nrCommonPrefix: commonPrefixCount(match.pathname, pathname),
  })).sort((a, b) => b.nrCommonPrefix - a.nrCommonPrefix)[0];

  return match;
}
