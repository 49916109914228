import { Button, Card, Responsive, Select, Space } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";
import { Routes, Route, Navigate, Link } from "react-router-dom";

import { useAuth } from "../../auth/AuthContext.js";
import { useGtag } from "../../client/hooks.js";
import { useLocale } from "../../utils/LocaleContext.js";

import SettingsStores from "./SettingsStores.jsx";

function Settings() {
  const { t } = useTranslation("settings");
  const { trackEvent } = useGtag();
  const { locale, setLocale, languageMapWithBrowser } = useLocale();
  const { logout } = useAuth();

  const baseSettings = (
    <Responsive.Space direction="vertical">
      <Card title={t("Language")}>
        <Select value={locale} onChange={value => { setLocale(value); trackEvent("click", "settings", "change language"); }} style={{ width: "170px" }}>
          {Object.entries(languageMapWithBrowser).map(([l, lName]) => (
            <Select.Option value={l} key={l}>{lName}</Select.Option>
          ))}
        </Select>
      </Card>
      <Card title={t("Stores")}>
        <Link to="./stores"><Button>{t("Stores")}</Button></Link>
      </Card>
      <Card title={t("Account")}>
        <Space>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Button href={`${process.env.REACT_APP_AUTH_URL}/account/`} target="" onClick={() => trackEvent("click", "settings", "account link")}>{t("Account")}</Button>
          <Button onClick={() => { trackEvent("click", "settings", "logout"); logout(); }}>{t("Logout")}</Button>
        </Space>
      </Card>
    </Responsive.Space>
  );

  return (
    <Routes>
      <Route path="" element={baseSettings} />
      <Route path="stores">
        <Route index element={<Navigate to="new" replace />} />
        <Route path=":tab/*" element={<SettingsStores />} />
      </Route>
      <Route path="*" element={<div>{t("Page not found...")}</div>} />
    </Routes>
  );
}

export default Settings;
