import { BlankPage, Button } from "@sellevate-team/ui-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../auth/AuthContext.js";

export default function SignupIncomplete() {
  const { t } = useTranslation();
  const { user } = useAuth(true);
  const location = useLocation();

  // Redirect the user to the landing page, when the signup is already completed
  if (user && user.signupCompleted && location.pathname === "/signup-incomplete") {
    return <Navigate to="/" replace />;
  }

  return (
    <BlankPage inCard cardTitle={t("Complete signup process")}>
      <p>
        {t("Before you can use Store Management, you must complete the registration process. You can easily do this by clicking the button below.")}
      </p>
      <Button type="primary" href={`${process.env.REACT_APP_AUTH_URL}/signup/`} target="_self">{t("Complete")}</Button>
    </BlankPage>
  );
}

export function SignupRequired({ children }) {
  const { user } = useAuth();
  const location = useLocation();

  // Redirect the user to the signup-incomplete page, when the signup is not completed yet
  if (user && !user.signupCompleted && location.pathname !== "/signup-incomplete") {
    return <Navigate to="/signup-incomplete" replace />;
  }

  return children;
}

SignupRequired.propTypes = {
  children: PropTypes.node,
};
