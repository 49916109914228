import en_US from "antd/lib/locale/en_US";
import nl_NL from "antd/lib/locale/nl_NL";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { languageMap, defaultLanguage } from "../languages.js";

const BROWSERKEY = "browser";
const LANGUAGECOOKIEKEY = "i18next";

const antLocaleLookup = {
  en: en_US,
  nl: nl_NL,
};

const antLocaleKeys = Object.keys(antLocaleLookup);

dayjs.locale(defaultLanguage);
dayjs.extend(LocalizedFormat);

const LocaleContext = createContext(null);

export function LocaleProvider({ children }) {
  const [locale, setLocale] = useState(Cookies.get(LANGUAGECOOKIEKEY) || BROWSERKEY);
  const { t, i18n } = useTranslation("settings");

  const languageMapWithBrowser = { [BROWSERKEY]: t("Browser language"), ...languageMap };

  const antLanguages = i18n.languages.filter(l => antLocaleKeys.includes(l));
  const antLocale = antLocaleLookup[antLanguages[0] || defaultLanguage];

  useEffect(() => {
    if (locale === BROWSERKEY) {
      dayjs.locale();
    } else {
      dayjs.locale(locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleLocale(language) {
    setLocale(language);
    if (language === BROWSERKEY) {
      Cookies.remove(LANGUAGECOOKIEKEY);
      i18n.changeLanguage();
      dayjs.locale();
    } else {
      Cookies.set(LANGUAGECOOKIEKEY, language);
      i18n.changeLanguage(language);
      dayjs.locale(language);
    }
  }

  const value = {
    locale,
    setLocale: handleLocale,
    antLocale,
    languageMap,
    languageMapWithBrowser,
    defaultLanguage,
  };

  return (
    <LocaleContext.Provider value={value}>
      {children(value)}
    </LocaleContext.Provider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.func,
};

export function useLocale() {
  return useContext(LocaleContext);
}
