/* eslint-disable i18next/no-literal-string */
/* eslint-env node */

const languageMap = {
  en: "English",
  nl: "Nederlands",
};

const languages = Object.keys(languageMap);
const defaultLanguage = "en";

exports.languageMap = languageMap;
exports.languages = languages;
exports.defaultLanguage = defaultLanguage;
