import { LoadingOutlined } from "@ant-design/icons";
import { ConfigProvider } from "antd";
import { t } from "i18next";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import App from "./App.jsx";
import { AuthProvider } from "./auth/AuthContext.js";
import { retryTimes } from "./client/utils.js";
import ChooseSubscription, { SubscriptionRequired } from "./pages/blanks/ChooseSubscription.jsx";
import SignupIncomplete, { SignupRequired } from "./pages/blanks/SignupIncomplete.jsx";
import UpgradeSubscription, { StoreManagementAccessRequired } from "./pages/blanks/UpgradeSubscription.jsx";

// eslint-disable-next-line import/order
import { CustomConfigProvider, DynamicNavigationProvider, HighlightProvider, LoadingProvider } from "@sellevate-team/ui-components";
import { useProductItems } from "./routes.js";
import { LocaleProvider } from "./utils/LocaleContext.js";
import ScrollToTop from "./utils/ScrollToTop.js";
import { SearchParamsStateProvider } from "./utils/searchParamsState.js";
import { StoreInfoProvider } from "./utils/StoreInfoContext.js";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: retryTimes,
    }
  }
});

export default function ContextManager() {
  return (
    <CustomConfigProvider loadingIndicator={<LoadingOutlined />}>
      <LocaleProvider>
        {locale => (
          <LoadingProvider defaultShowSplashScreen={true} logoContext={t("Store Management")}>
            <ConfigProvider locale={locale.antLocale}>
              <BrowserRouter>
                <ScrollToTop />
                <SearchParamsStateProvider>
                  <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                      <AuthContextManager />
                    </AuthProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </QueryClientProvider>
                </SearchParamsStateProvider>
              </BrowserRouter>
            </ConfigProvider>
          </LoadingProvider>
        )}
      </LocaleProvider>
    </CustomConfigProvider>
  );
}

function AuthContextManager() {
  const productItems = useProductItems();
  return (
    <DynamicNavigationProvider
      products={productItems}
      selectedProduct={"0"}>
      <HighlightProvider>
        <Routes>
          <Route index element={<Navigate to="/all" replace />} />
          <Route path="signup-incomplete" element={<SignupIncomplete />} />
          <Route path="choose-subscription" element={<ChooseSubscription />} />
          <Route path="upgrade-subscription" element={<UpgradeSubscription />} />
          <Route path=":storeIds/*" element={
            <SignupRequired>
              <SubscriptionRequired>
                <StoreManagementAccessRequired>
                  <StoreInfoProvider>
                    <App />
                  </StoreInfoProvider>
                </StoreManagementAccessRequired>
              </SubscriptionRequired>
            </SignupRequired>
          } />
        </Routes>
      </HighlightProvider>
    </DynamicNavigationProvider>
  );
}
