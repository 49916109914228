import { NotificationCarousel } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";

export function OrderDetailHelpDescription({ ...props }) {
  const { t } = useTranslation("help", { keyPrefix: "orderDetail" });
  return (
    <NotificationCarousel highlights={["order-detail-statistics", "order-detail-order", "order-detail-side"]} {...props}>
      <div>
        {t("At the top of the order page, the most important information of the order is displayed.")}
      </div>
      <div>
        {t("The products that have been ordered are shown here. You can also navigate directly to the product page.")}
      </div>
      <div>
        {t("The customer information is shown on the right.")}
      </div>
    </NotificationCarousel>
  );
}
