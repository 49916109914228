/* eslint-disable i18next/no-literal-string */
import { useLoading } from "@sellevate-team/ui-components";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect } from "react";

import { useUser } from "../client/hooks";
import { combinePermissions, convertToPermission, Permission } from "../components/permissions/utils";

// Create a context for storing the auth details
const AuthContext = createContext(null);

// Create a provider, wrapping the auth context
export function AuthProvider({ children }) {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

// Function for creating the auth context value
function useProvideAuth() {
  const { isLoading, isError, data, error } = useUser();
  const { setSplashScreen } = useLoading();

  useEffect(() => {
    if (!isLoading) {
      // When the user has been authenticated, remove the splash screen
      setSplashScreen(false);
    }
  }, [isLoading, setSplashScreen]);

  const login = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_SITE_URL}/login/`;
  }, []);

  const logout = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_SITE_URL}/logout/`;
  }, []);

  const permissions = {
    orders: !!data?.subscription?.updateOrdersPermission,
    researchDashboard: convertToPermission(data?.subscription?.features?.dashboardPermission, Permission.LOCKED),
    researchTracker: convertToPermission(data?.subscription?.features?.productTrackerPermission, Permission.LOCKED),
    researchKeywords: convertToPermission(data?.subscription?.features?.keywordsPermission, Permission.LOCKED),
    researchDatabase: convertToPermission(data?.subscription?.features?.productDatabasePermission, Permission.LOCKED),
    researchCalculator: convertToPermission(data?.subscription?.features?.calculatorPermission, Permission.LOCKED),
  };

  permissions.researchSearch = combinePermissions([permissions.researchKeywords, permissions.researchDatabase]);

  return {
    isLoading,
    isError,
    user: data,
    permissions,
    error,
    login,
    logout,
  };
}

// Create a custom hook for using the auth context
export function useAuth(requireAuth = false) {
  const auth = useContext(AuthContext);

  const isError = auth.isError;
  const errorStatus = auth.error?.status;
  const login = auth.login;

  useEffect(() => {
    if (requireAuth && isError && errorStatus === 403) {
      login();
    }
  }, [requireAuth, isError, errorStatus, login]);

  return auth;
}
