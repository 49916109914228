/* eslint-disable i18next/no-literal-string */
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { languages, defaultLanguage } from "./languages.js";

// eslint-disable-next-line no-undef
const production = process.env.NODE_ENV === "production";

const DEFAULT_NAMESPACE = "translation";
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

// eslint-disable-next-line import/no-named-as-default-member
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: defaultLanguage,
    supportedLngs: languages,
    nonExplicitSupportedLngs: true,
    debug: process.env.REACT_APP_DEBUG_I18N && !production,
    keySeparator: "->",
    pluralSeparator: "->",
    contextSeparator: "->",
    nsSeparator: false,
    returnEmptyString: false,
    returnedObjectHandler: (key, value, options) => {
      // If no translation is provided, return the key (surrounded by square brackets in development)
      return production ? key : `[${key}]`;
    },

    ns: [DEFAULT_NAMESPACE, "settings", "help"], // Add other namespaces here
    defaultNS: DEFAULT_NAMESPACE,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    },

    detection: {
      caches: ""
    }
  });


export default i18n;
