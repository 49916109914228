import { LineChartOutlined, AreaChartOutlined } from "@ant-design/icons";
import { Segmented, Tooltip } from "@sellevate-team/ui-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";


export default function PlotTypeSelection({ value, onChange, cumulativeDisabled }) {
  const { t } = useTranslation();

  return (
    <Segmented
      options={[
        {
          value: false,
          icon: <Tooltip title={t("Incremental")}><LineChartOutlined /></Tooltip>,
        },
        {
          value: true,
          disabled: cumulativeDisabled,
          icon: <Tooltip title={t("Cumulative")}><AreaChartOutlined /></Tooltip>,
        },
      ]}
      onChange={onChange}
      value={value}
    />
  );
}

PlotTypeSelection.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  cumulativeDisabled: PropTypes.bool,
};
