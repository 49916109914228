/* eslint-disable i18next/no-literal-string */
import { useRef } from "react";
import { useQuery, useInfiniteQuery, useMutation } from "react-query";

import { useAuth } from "../auth/AuthContext.js";

import client from "./client.js";

export function useUser() {
  const { setGtagUserId } = useGtag();
  return useQuery(["user"], ({ queryKey }) => {
    return client.get({ queryKey }).then(user => {
      setGtagUserId(user.uid);
      return user;
    });
  }, {
    retry: false,
    keepPreviousData: true,
  });
}

export function useStoreList() {
  const auth = useAuth();
  return useQuery(["stores"], client.get, {
    enabled: !!auth.user,
  });
}

export function useStoreDetail(storeIds) {
  return useQuery(["stores", storeIds], client.get);
}

export function useStoreDashboard(storeIds, from, to, groupBy = "") {
  return useQuery(["stores", storeIds, "dashboard", { from, to, groupBy }], client.get, {
    keepPreviousData: true,
  });
}

export function useProductList(storeIds) {
  return useQuery(["stores", storeIds, "products"], client.get);
}

export function useProductListInfinite(storeIds, from, to, search, ordering) {
  return useInfiniteQuery(["stores", storeIds, "products", { from, to, search, ordering }], ({ queryKey, pageParam = undefined }) => {
    const queryCopy = [...queryKey];
    queryCopy[queryCopy.length - 1] = { ...queryCopy.at(-1), cursor: pageParam };
    return client.get({ queryKey: queryCopy });
  }, {
    getNextPageParam: (lastPage, pages) => lastPage.next && (new URL(lastPage.next))?.searchParams?.get("cursor"),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000, // TODO: Check times
    cacheTime: 5000,
  });
}

export function useProductDetail(storeIds, productId, from, to, groupBy = "") {
  return useQuery(["stores", storeIds, "products", productId, { from, to, groupBy }], client.get, {
    keepPreviousData: true,
  });
}

export function useOrderListInfinite(storeIds, from, to, search) {
  const { permissions } = useAuth();
  return useInfiniteQuery(["stores", storeIds, "orders", { from, to, search }], ({ queryKey, pageParam = undefined }) => {
    const queryCopy = [...queryKey];
    queryCopy[queryCopy.length - 1] = { ...queryCopy.at(-1), cursor: pageParam };
    return client.get({ queryKey: queryCopy });
  }, {
    getNextPageParam: (lastPage, pages) => lastPage.next && (new URL(lastPage.next))?.searchParams?.get("cursor"),
    refetchOnWindowFocus: false,
    staleTime: 5000, // TODO: Check times
    cacheTime: 5000,
    enabled: permissions.orders,
  });
}

export function useOrderDetail(storeIds, orderId) {
  return useQuery(["stores", storeIds, "orders", orderId], client.get);
}

export function useReturnListInfinite(storeIds, from, to, search) {
  const { permissions } = useAuth();
  return useInfiniteQuery(["stores", storeIds, "returns", { from, to, search }], ({ queryKey, pageParam = undefined }) => {
    const queryCopy = [...queryKey];
    queryCopy[queryCopy.length - 1] = { ...queryCopy.at(-1), cursor: pageParam };
    return client.get({ queryKey: queryCopy });
  }, {
    getNextPageParam: (lastPage, pages) => lastPage.next && (new URL(lastPage.next))?.searchParams?.get("cursor"),
    refetchOnWindowFocus: false,
    staleTime: 5000, // TODO: Check times
    cacheTime: 5000,
    enabled: permissions.orders,
  });
}

export function useReturnDetail(storeIds, orderId) {
  return useQuery(["stores", storeIds, "returns", orderId], client.get);
}

export function useConnectStoresDiscover() {
  return useQuery(["connect", "stores", "discover"], client.get);
}

export function useConnectStores({ refetchInterval = undefined }) {
  return useQuery(["connect", "stores"], client.get, {
    refetchInterval: refetchInterval,
  });
}

export function useConnectStoresDetail(storeIds) {
  return useQuery(["connect", "stores", storeIds], client.get);
}

export function useConnectStoresDetailMutation(storeIds, options = {}) {
  let { url, searchParamsString } = client.createApiUrl(["connect", "stores", storeIds]);
  if (searchParamsString !== "") {
    url = `${url}?${searchParamsString}`;
  }
  return useMutation(data => client.patch(url, data), options);
}

export function useConnectStoresRemoveMutation(storeIds, options = {}) {
  let { url, searchParamsString } = client.createApiUrl(["connect", "stores", storeIds]);
  if (searchParamsString !== "") {
    url = `${url}?${searchParamsString}`;
  }
  return useMutation(data => client.delete(url, data), options);
}

export function useConnectStoresRestoreMutation(storeIds, options = {}) {
  let { url, searchParamsString } = client.createApiUrl(["connect", "stores", storeIds, "restore"]);
  if (searchParamsString !== "") {
    url = `${url}?${searchParamsString}`;
  }
  return useMutation(data => client.post(url, data), options);
}

export function useGtag() {
  const userIdRef = useRef(null);

  function setGtagUserId(id) {
    try {
      if (id && userIdRef.current !== id) {
        gtag("set", {
          "user_id": id
        });
        userIdRef.current = id;
      }
    } catch (error) {
      console.error(error);
    }
  }

  function trackEvent(action, category, label, value = undefined) {
    try {
      gtag("event", action, {
        "event_category": category,
        "event_label": label,
        "value": value
      });
    } catch (error) {
      console.error(error);
    }
  }

  return { trackEvent, setGtagUserId };
}
