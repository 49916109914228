import { NotificationCarousel } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";

export function OrderListHelpDescription({ ...props }) {
  const { t } = useTranslation("help", { keyPrefix: "orderList" });
  return (
    <NotificationCarousel highlights={["order-list-list", "breadcrumb-controls"]} {...props}>
      <div>
        {t("In the order list you can see which customer has made a purchase in your store. The most important information is shown in this list, more detailed information is displayed when you click on an order.")}
      </div>
      <div>
        {t("Use the period selection to see orders from other periods. Search for orders using the search button.")}
      </div>
    </NotificationCarousel>
  );
}
