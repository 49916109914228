/* eslint-disable i18next/no-literal-string */
function fallbackCopyToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    return successful;
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    return new Promise((resolve, reject) => {
      const success = fallbackCopyToClipboard(text);
      if (success) {
        resolve();
      }
      reject();
    });
  }
  return navigator.clipboard.writeText(text);
}
