import PropTypes from "prop-types";

function CustomTooltip({ data, yProperty, xProperty, xFormat, keys, translationLookup, formatterLookup }) {
  let xValue = data?.[xProperty];
  if (data?.[xProperty] instanceof Date) xValue = data?.[xProperty]?.toISOString().split("T")[0];
  if (xFormat) xValue = xFormat(data?.[xProperty]);

  const rows = keys.map(key => ({
    key,
    highlight: yProperty === key,
    label: translationLookup[key],
    value: data?.[key],
  })).sort((a, b) => a.highlight === b.highlight ? 0 : a.highlight ? -1 : 1);

  return (
    <>
      {data?.[xProperty] &&
        <div style={{ fontWeight: "bold" }}>{xValue} - <span style={{ color: data?.serie?.color }}>{data?.serie?.label}</span></div>
      }
      <div>
        {rows.map(row => {
          // eslint-disable-next-line i18next/no-literal-string
          const style = row.highlight ? { fontWeight: "bold", borderBottom: "1px solid lightgrey", marginBottom: "5px", paddingBottom: "5px", } : {};
          const formatter = formatterLookup[row.key] ?? (value => value);
          return (
            <div key={row.key} style={{ ...style, whiteSpace: "nowrap", display: "flex", justifyContent: "space-between" }}>
              <span>{row.label}:</span>
              <span style={{ marginLeft: "5px" }}>{formatter(row.value ?? 0)}</span>
            </div>
          );
        })}
      </div>
    </>
  );
}

CustomTooltip.propTypes = {
  data: PropTypes.object,
  yProperty: PropTypes.string,
  xProperty: PropTypes.string,
  labelPropertyList: PropTypes.array,
  xFormat: PropTypes.func,
  yFormat: PropTypes.func,
  keys: PropTypes.array,
  translationLookup: PropTypes.object,
  formatterLookup: PropTypes.object,
};

CustomTooltip.defaultProps = {
  yProperty: "y",
  xProperty: "x",
  labelPropertyList: ["serie", "label"],
  keys: []
};

CustomTooltip.parentStyle = {
  backgroundColor: "black",
  opacity: 0.8,
  borderRadius: "10px",
  color: "white",
  padding: "10px",
  width: "fit-content",
  zIndex: "1"
};

export default CustomTooltip;
