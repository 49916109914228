

export function isObject(value) {
  return typeof value === "object" && !Array.isArray(value) && value !== null;
}

/**
 * 
 * @param {string} str
 * @returns {function} random seed generator based on given string
 */
export function xmur3(str) {
  for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353), h = h << 13 | h >>> 19;
  return function () {
    h = Math.imul(h ^ h >>> 16, 2246822507);
    h = Math.imul(h ^ h >>> 13, 3266489909);
    return (h ^= h >>> 16) >>> 0;
  };
}

/**
 * 
 * @param {any} seed
 * @returns {function} Random number generator based on given seed
 */
export function mulberry32(seed) {
  return function () {
    var t = seed += 0x6D2B79F5;
    t = Math.imul(t ^ t >>> 15, t | 1);
    t ^= t + Math.imul(t ^ t >>> 7, t | 61);
    return ((t ^ t >>> 14) >>> 0) / 4294967296;
  };
}

export function createRandomGenerator(str) {
  const seedGen = xmur3(str);
  let seed = seedGen();
  return mulberry32(seed);
}

export function shuffleWord(str, rand) {
  let temp = str.split("");
  temp = temp.sort(() => (0.5 - rand()));
  return temp.join("");
}

export function randomizeNumberString(str, rand) {
  let lower = Math.pow(10, str.length - 1);
  let upper = Math.pow(10, str.length);
  return String(Math.floor(rand() * (upper - lower) + lower));
}

export function shuffle(str, rand = null) {
  if (rand === null) {
    rand = Math.random;
  }

  let r = /,| |\.|€/g;
  let out = String(str).split(r);
  let separators = String(str).match(r) || [];
  out = out.map(c => {
    if (!isNaN(c) && c !== "") {
      return randomizeNumberString(c, rand);
    }
    return shuffleWord(c, rand);
  });
  return out.map((v, i) => i < separators.length ? v + separators[i] : v).join("");
}
