
import { DownOutlined, CheckOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space, useHighlight } from "@sellevate-team/ui-components";
import { cls } from "@sellevate-team/ui-components/dist/utils";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styles from "./NavBarStores.module.scss";

function NavBarStores({ stores, selectedStoreIds, availableStoreIds, selectedStoresExist, currentPath, onOpenChange, onSelectAll }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const storeSelectionHighlight = useHighlight("store-selection");

  if (!stores) {
    return null;
  }

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    a = a.sort();
    b = b.sort();
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const descSort = (array) => {
    return array.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  };

  const navToSelect = ({ key, only }) => {
    if (key === "all") {
      if (availableStoreIds?.length === 0) {
        // eslint-disable-next-line i18next/no-literal-string
        navigate("/all/settings/stores/new");
      } else {
        selectedStoreIds = availableStoreIds;
        navigate(`../../all${currentPath && "/" + currentPath}`);
      }
      onSelectAll();
      setVisible(false);
      return;
    }
    if (key === "manage-stores") {
      // eslint-disable-next-line i18next/no-literal-string
      navigate("/all/settings/stores/connected");
      setVisible(false);
      return;
    }
    if (only) {
      navigate(`../../${key}${currentPath && "/" + currentPath}`);
      setVisible(false);
      return;
    }
    selectedStoreIds = selectedStoreIds.filter(id => availableStoreIds.includes(id));
    selectedStoreIds.push(key);
    if (arraysEqual(selectedStoreIds, availableStoreIds)) {
      navigate(`../../all${currentPath && "/" + currentPath}`);
    } else {
      navigate(`../../${descSort(selectedStoreIds).join("+")}${currentPath && "/" + currentPath}`);
    }
  };

  const navToDeselect = ({ key }) => {
    selectedStoreIds = selectedStoreIds.filter(id => availableStoreIds.includes(id));
    selectedStoreIds.splice(selectedStoreIds.indexOf(key), 1);
    if (selectedStoreIds.length === 0) {
      selectedStoreIds = availableStoreIds;
      navigate(`../../all${currentPath && "/" + currentPath}`);
    } else {
      navigate(`../../${descSort(selectedStoreIds).join("+")}${currentPath && "/" + currentPath}`);
    }
  };

  const storeItems = stores.map(d => ({
    key: d.id,
    label: (
      <div className={styles["menu-item"]}>
        {d.status === "failed" ? (
          <span className={`${styles["badge"]} ${styles["failed-badge"]}`} title={t("Updating store failed")}></span>
        ) : !d?.credentials?.valid ? (
          <span className={`${styles["badge"]} ${styles["invalid-credentials-badge"]}`} title={t("Invalid credentials")}></span>
        ) : dayjs().isAfter(dayjs(d?.credentials?.expirationNoticeAt)) ? (
          <span className={`${styles["badge"]} ${styles["expiring-credentials-badge"]}`} title={t("Credentials are expiring")}></span>
        ) : d?.onboarding ? (
          <span className={`${styles["badge"]} ${styles["onboarding-badge"]}`} title={t("Onboarding in process")}></span>
        ) : null}
        <span className={styles["item-text"]}>{d.name}</span>
        <Button onClick={(e) => { navToSelect({ key: d.id, only: true }); e.stopPropagation(); }} className={styles["button"]} size="small" shape="circle" icon={<ArrowRightOutlined />} />
        {selectedStoreIds.includes(d.id) && <span className={styles["check"]}><CheckOutlined /></span>}
      </div >
    ),
    name: d.name,
  })).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  const extraItems = [{
    type: "divider"
  }, {
    key: "all",
    label: availableStoreIds?.length === 0 ? t("Connect store") : t("Select all stores"),
  }, {
    key: "manage-stores",
    label: t("Manage stores"),
  }].filter(d => availableStoreIds?.length === 0 ? d.key !== "manage-stores" : true);

  const menu = (
    <Menu
      multiple
      selectable
      selectedKeys={selectedStoreIds}
      onSelect={navToSelect}
      onDeselect={navToDeselect}
      items={storeItems.concat(extraItems)}
      className={styles["stores-menu"]}
    />
  );

  let selectedText;
  if (availableStoreIds?.length === 0) {
    selectedText = t("No stores connected yet");
  } else if (!selectedStoresExist) {
    selectedText = t("Invalid store selected");
  } else if (selectedStoreIds?.length === 1) {
    const s = stores.find(i => selectedStoreIds[0] === i.id);
    selectedText = t("{{store}} selected", { store: s?.name });
    if (s?.status === "failed") {
      selectedText = <>
        <span className={`${styles["badge"]} ${styles["failed-badge"]} ${styles["in-nav-bar"]}`} title={t("Updating store failed")}></span>
        {selectedText}
      </>;
    } else if (!s?.credentials?.valid) {
      selectedText = <>
        <span className={`${styles["badge"]} ${styles["invalid-credentials-badge"]} ${styles["in-nav-bar"]}`} title={t("Invalid credentials")}></span>
        {selectedText}
      </>;
    } else if (dayjs().isAfter(dayjs(s?.credentials?.expirationNoticeAt))) {
      selectedText = <>
        <span className={`${styles["badge"]} ${styles["expiring-credentials-badge"]} ${styles["in-nav-bar"]}`} title={t("Credentials are expiring")}></span>
        {selectedText}
      </>;
    } else if (s?.onboarding) {
      selectedText = <>
        <span className={`${styles["badge"]} ${styles["onboarding-badge"]} ${styles["in-nav-bar"]}`} title={t("Onboarding in process")}></span>
        {selectedText}
      </>;
    }
  } else if (arraysEqual(availableStoreIds, selectedStoreIds)) {
    selectedText = t("All stores selected");
  } else {
    selectedText = t("{{count}} stores selected", { count: selectedStoreIds.length });
  }

  return (
    <Dropdown className={styles["store-dropdown"]}
      overlay={menu}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={bool => { setVisible(bool); onOpenChange(bool); }}>
      <a onClick={e => e.preventDefault()}>
        <div
          {...storeSelectionHighlight?.props}
          className={cls(styles["selection-space"], storeSelectionHighlight?.className)}
        >
          <span className={styles["selected-text"]}>{selectedText}</span>
          <DownOutlined />
        </div>
      </a>
    </Dropdown>
  );
}

NavBarStores.propTypes = {
  stores: PropTypes.array,
  selectedStoreIds: PropTypes.array,
  availableStoreIds: PropTypes.array,
  selectedStoresExist: PropTypes.bool,
  currentPath: PropTypes.string,
  onOpenChange: PropTypes.func,
  onSelectAll: PropTypes.func,
};

export default NavBarStores;
