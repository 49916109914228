import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Collapse, Responsive, Input, message, Avatar, Modal, Form, Divider, Space, Empty, Spin, Alert } from "@sellevate-team/ui-components";
import { notification } from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams, useNavigate } from "react-router-dom";

import { useAuth } from "../../../auth/AuthContext";
import client from "../../../client/client";
import { useConnectStoresDiscover } from "../../../client/hooks";
import { useDynamicPathLabel } from "../../../utils/router";
import { usePageSearchParams, useSearchParamsState } from "../../../utils/searchParamsState";

import ConnectStoreHelp from "./components/ConnectStoreHelp.jsx";

import styles from "./Stores.module.scss";


function toFieldType(field) {
  if (field.type === "text") {
    return <Input placeholder={field.label} />;
  }
  if (field.type === "password") {
    // autoComplete: one-time-code prevents the browser from filling in/storing the API keys
    return <Input.Password autoComplete="one-time-code" placeholder={field.label} />;
  }
}

function StoreConnectForm({ storeSlug, option, onSuccess, updateAction, subText }) {
  const { t } = useTranslation("settings");
  const [form] = Form.useForm();
  const mutation = useMutation(data => client.post(option.action, data), {
    onError: (error, variables, context) => {
      if (error?.data?.data?.__all__?.includes("invalid_credentials")) {
        message.error(t("Invalid credentials, please try again."));
      } else if (error?.data?.already_connected) {
        message.error(t("This store is already connected to your account."));
      } else if (error?.data?.incorrect_store) {
        message.error(t("The new credentials do not correspond to the existing store, try another store."));
      } else {
        message.error(t("Something went wrong, please try again."));
      }
    },
    onSuccess: (data, variables, context) => {
      if (updateAction) {
        if (!data.renewAuthUrl) {
          message.success(t("Store credentials updated successfully"));
        }
      } else {
        if (data.status !== "draft") {
          message.success(t("Store added successfully"));
        }
      }
      onSuccess(data);
    },
  });

  const onFinish = (values) => {
    const data = {
      store: storeSlug,
      option: option.name,
      data: values,
    };

    mutation.mutate(data);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <Divider>{option.title}</Divider>
      {subText}
      <Form
        form={form}
        name={option.name}
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 16 }}
        initialValues={{}}
        onFinish={onFinish}
        autoComplete="off"
      >
        {Object.entries(option.fields).map(([field_name, field], i) => (
          <Form.Item
            key={field_name}
            style={{ justifyContent: "center" }}
            name={field_name}
            rules={[
              { required: field.required, message: t("This field is required.") },
              { max: field.max_length, message: t("Value exceeds max length of {{ max }}", { max: field.max_length }) },
            ]}
          >
            {toFieldType(field)}
          </Form.Item>
        ))}

        <Form.Item shouldUpdate
          style={{ justifyContent: "center" }}>
          {() => (
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={mutation.isLoading}
                disabled={
                  !form.isFieldsTouched(true) ||
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length > 0
                }>
                {t("Connect")}
              </Button>
              {Object.entries(option.fields).length > 0 && (
                <Button htmlType="button" onClick={onReset}>
                  {t("Reset")}
                </Button>
              )}
              {option.info_url && (
                <Button htmlType="button" href={option.info_url}>
                  {t("Info")}
                </Button>
              )}
            </Space>
          )}
        </Form.Item>
      </Form>
    </>
  );
}

StoreConnectForm.propTypes = {
  storeSlug: PropTypes.string,
  option: PropTypes.object,
  onSuccess: PropTypes.func,
  updateAction: PropTypes.bool,
  subText: PropTypes.node,
};

export { StoreConnectForm };

function AdvancedOptionsCollapse({ children }) {
  const { t } = useTranslation("settings");
  return (
    <Collapse>
      <Collapse.Panel header={t("Advanced options")}>
        {children}
      </Collapse.Panel>
    </Collapse>
  );
}

AdvancedOptionsCollapse.propTypes = {
  children: PropTypes.node,
};

function StoresDiscover() {
  const { t } = useTranslation("settings");
  const { isLoading, isError, data } = useConnectStoresDiscover();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { currentPageParams } = useSearchParamsState();
  const initialConnectStoreHelp = currentPageParams["connect-help"] === "true";
  const [connectStoreHelp, setConnectStoreHelp] = useState(initialConnectStoreHelp);

  const selectedItem = data?.find(item => item.slug === slug);

  // With an item selected, add the name of the item as the breadcrumb path label
  useDynamicPathLabel(selectedItem?.name);

  usePageSearchParams({
    // eslint-disable-next-line i18next/no-literal-string
    "connect-help": connectStoreHelp ? "true" : undefined,
  });

  useEffect(() => {
    if (connectStoreHelp) {
      setConnectStoreHelp(false);
      notification.info({
        key: "connect-store-help",
        message: t("Connect a store"),
        description: <ConnectStoreHelp />,
        placement: "bottomRight",
        duration: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectStoreHelp]);

  // Close the popup/modal when no item is found in the data (and the data is loaded)
  // or when the user does not have permission for connecting to new stores
  const noItemFound = !isLoading && !selectedItem && slug;
  const noPermission = user && !user?.subscription?.connectStorePermission;
  useEffect(() => {
    if (noItemFound || noPermission) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noItemFound, noPermission]);

  if (isError) {
    return <span>{t("Error...")}</span>;
  }

  if (isLoading) {
    return (
      <Responsive.Row justify={"space-around"}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
          <div className={styles["empty-loading-container"]}>
            <LoadingOutlined />
          </div>
        </Empty>
      </Responsive.Row>
    );
  }

  const showModal = (item) => {
    navigate(`./${item.slug}`);
  };

  const handleClose = () => {
    navigate("../");
  };

  const handleSuccess = (data) => {
    if (data.status === "draft" && data.continueAuthUrl) {
      // Redirect to the continue auth url
      window.location.href = data.continueAuthUrl;
    } else {
      navigate(`../../connected/${data.id}/`);
    }
  };

  // Split the connect options by primary and advanced arrays
  const primaryOptions = selectedItem?.options.filter(option => option.primary);
  const advancedOptions = selectedItem?.options.filter(option => !option.primary);
  let AdvancedWrapper = ({ children }) => children;

  // If there are both primary options and advanced options,
  // Show the advanced options inside a collapse
  if (primaryOptions?.length > 0 && advancedOptions?.length > 0) {
    AdvancedWrapper = AdvancedOptionsCollapse;
  }

  return (
    <>
      <Responsive.Space direction="vertical">
        <Alert type="info"
          showIcon
          message={t("Choose the type of store you wish to connect to your Sellevate account.")} />
        <div className={styles["available-stores"]}>
          {data?.map((item, i) => (
            <Card key={i} actions={[<Button key="list-connect" disabled={!user?.subscription?.connectStorePermission} onClick={() => showModal(item)}>{t("Connect")}</Button>]}>
              <Card.Meta avatar={<Avatar src={item.icon} />} title={item.name} />
            </Card>
          ))}
        </div>
      </Responsive.Space>
      <Modal
        title={selectedItem?.name}
        visible={slug !== undefined}
        onCancel={handleClose}
        footer={null}
        destroyOnClose>
        <Spin spinning={isLoading}>
          <p>
            {t("Connect a {{storeType}} store by choosing a connect option and clicking ‘$t(Connect)’.", { storeType: selectedItem?.name })}
          </p>
          <p>
            {t("Thanks to this connection, you don't have to transfer all your data to the Sellevate dashboard. Of course we handle the data securely.")}
          </p>
          {primaryOptions?.map(option => (
            <StoreConnectForm
              key={option.name}
              storeSlug={selectedItem?.slug}
              option={option}
              onSuccess={handleSuccess}
            />
          ))}
          <AdvancedWrapper>
            {advancedOptions?.map(option => (
              <StoreConnectForm
                key={option.name}
                storeSlug={selectedItem?.slug}
                option={option}
                onSuccess={handleSuccess}
              />
            ))}
          </AdvancedWrapper>
        </Spin>
      </Modal>
    </>
  );
}

export default StoresDiscover;
