import { EuroOutlined } from "@ant-design/icons";
import { Button, Empty, Space } from "@sellevate-team/ui-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";


export default function NoPermissionEmpty({ subject, includeButton }) {
  const { t } = useTranslation();

  let description;
  if (subject) {
    description = t("Not available, choose a subscription to see {{ subject }}.", { subject });
  } else {
    description = t("Not available, choose a subscription.");
  }

  return (
    <Space direction="vertical">
      <Empty image={<EuroOutlined style={{ fontSize: "3em", height: "100%" }} />} description={description} />
      {includeButton && <Button type="primary" href={`${process.env.REACT_APP_AUTH_URL}/`} target="_self">{t("View subscriptions")}</Button>}
    </Space>
  );
}

NoPermissionEmpty.propTypes = {
  subject: PropTypes.string,
  includeButton: PropTypes.bool,
};
