import { NotificationCarousel } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";

export function ProductListHelpDescription({ ...props }) {
  const { t } = useTranslation("help", { keyPrefix: "productList" });
  return (
    <NotificationCarousel highlights={["product-list-list", "breadcrumb-controls"]} {...props}>
      <div>
        {t("This list displays the products you sell in the selected stores. Use the sort options in the table to compare products. Click on a product to see a detailed page.")}
      </div>
      <div>
        {t("You can select a period over which the revenue will be calculated. Use the search option to easily find products.")}
      </div>
    </NotificationCarousel>
  );
}
