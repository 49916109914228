/* eslint-disable i18next/no-literal-string */
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useMatchingRoute } from "./router.js";
import { useSearchParamsState } from "./searchParamsState.js";

export const DateRangeStates = Object.freeze(
  {
    "PRODUCT": "PRODUCT",
    "ORDER": "ORDER",
    "RETURN": "RETURN",
  }
);

const DefaultRangeLookup = Object.freeze({
  [DateRangeStates.PRODUCT]: [dayjs().subtract(30, "days"), dayjs()],
  [DateRangeStates.ORDER]: [dayjs().subtract(1, "week"), dayjs()],
  [DateRangeStates.RETURN]: [dayjs().subtract(30, "days"), dayjs()],
});

function useSingleDateRangeState(name, activeName, queryState) {
  return [name, useState(name === activeName && queryState ? queryState : DefaultRangeLookup[name])];
}

function useDateRangeState(initialDateRange) {
  const match = useMatchingRoute();

  // Get the dateRange option for the matched route
  const activeDateRange = match?.route?.dateRange;

  // Construct the lookup state object for the date ranges of the different pages
  const lookupEntries = [];

  lookupEntries.push(useSingleDateRangeState(
    DateRangeStates.PRODUCT,
    activeDateRange,
    initialDateRange
  ));
  lookupEntries.push(useSingleDateRangeState(
    DateRangeStates.ORDER,
    activeDateRange,
    initialDateRange
  ));
  lookupEntries.push(useSingleDateRangeState(
    DateRangeStates.RETURN,
    activeDateRange,
    initialDateRange
  ));

  const lookups = Object.fromEntries(lookupEntries);

  const dateRangeStateFallback = useMemo(() => [undefined, undefined], []);
  const [dateRange, setDateRange] = activeDateRange in lookups ? lookups[activeDateRange] : dateRangeStateFallback;

  const defaultDateRangeFallback = useMemo(() => [undefined, undefined], []);
  const defaultDateRange = activeDateRange in DefaultRangeLookup ? DefaultRangeLookup[activeDateRange] : defaultDateRangeFallback;

  return {
    dateRange,
    setDateRange,
    defaultDateRange,
    activeDateRange
  };
}


const DateRangeContext = createContext(null);

export function DateRangeProvider({ dateRanges, children }) {
  const { searchParams, setSearchParams } = useSearchParamsState();

  // Compute the initial date range for the state a single time
  const initialDateRange = useMemo(() => {
    const fromStr = searchParams.get("from");
    const toStr = searchParams.get("to");
    const rangeStr = searchParams.get("range");

    // First prioritize the range param
    if (rangeStr) {
      const dateRangePreset = dateRanges?.find(range => range.key === rangeStr);
      if (dateRangePreset) {
        return [dayjs(dateRangePreset.startDate), dayjs(dateRangePreset.endDate)];
      }
    }

    // And then look for the from and to params
    if (fromStr && toStr) {
      const from = dayjs(fromStr, "YYYY-MM-DD");
      const to = dayjs(toStr, "YYYY-MM-DD");
      if (from.isBefore(to, "day")) {
        return [from, to];
      }
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { dateRange, setDateRange, defaultDateRange, activeDateRange } = useDateRangeState(initialDateRange);

  // State option
  useEffect(() => {
    setSearchParams(params => {
      const copyParams = new URLSearchParams(params.toString());

      const dateRangePreset = dateRanges?.find(range => (
        range?.startDate?.isSame(dateRange?.[0], "day")
        && range?.endDate?.isSame(dateRange?.[1], "day")
      ));

      if (dateRange && dateRange.map((d, i) => d.isSame(defaultDateRange[i], "day")).every(Boolean)) {
        // The selected date range is the default date range
        copyParams.delete("range");
        copyParams.delete("from");
        copyParams.delete("to");
      } else if (dateRangePreset) {
        // The selected date range is equal to a preset
        copyParams.set("range", dateRangePreset.key);
        copyParams.delete("from");
        copyParams.delete("to");
      } else {
        // The selected date range should be specified by a from and to date
        copyParams.delete("range");
        // If dateRange is defined and is not equal to the default date range, set the search params
        if (dateRange) {
          copyParams.set("from", dateRange[0].format("YYYY-MM-DD"));
          copyParams.set("to", dateRange[1].format("YYYY-MM-DD"));
        } else {
          // Otherwise, delete the range from the search params
          copyParams.delete("from");
          copyParams.delete("to");
        }
      }
      return copyParams;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchParams, dateRange, defaultDateRange]);

  const fromStr = dateRange?.[0]?.format("YYYY-MM-DD") || "";
  const toStr = dateRange?.[1]?.format("YYYY-MM-DD") || "";

  const value = {
    dateRange,
    setDateRange,
    dateRanges,
    fromStr,
    toStr,
    activeDateRange
  };

  return (
    <DateRangeContext.Provider value={value}>
      {children}
    </DateRangeContext.Provider>
  );
}

DateRangeProvider.propTypes = {
  dateRanges: PropTypes.array,
  children: PropTypes.node,
};

export function useDateRange() {
  return useContext(DateRangeContext);
}
