import { Space } from "@sellevate-team/ui-components";
import dayjs from "dayjs";
import { t } from "i18next";
import PropTypes from "prop-types";

import styles from "./ChartTitle.module.scss";

function ChartTitle({ title, fromDate, toDate }) {

  const fromDateFormatted = dayjs(fromDate).format("LL");
  const toDateFormatted = dayjs(toDate).format("LL");

  return (
    <Space className={styles["card-title"]}>
      <span>{title}</span>
      {fromDate && toDate && <span className={styles["sub-text"]}>{t("From {{ fromDate }} to {{ toDate }}", { fromDate: fromDateFormatted, toDate: toDateFormatted })}</span>}
      <div style={{ width: "10px" }} />
    </Space>
  );
}

ChartTitle.propTypes = {
  title: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
};

ChartTitle.defaultProps = {

};

export default ChartTitle;
