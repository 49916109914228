/* eslint-disable i18next/no-literal-string */
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import useDebounce from "./hooks/useDebounce.js";
import { useMatchingRoute } from "./router.js";
import { useSearchParamsState } from "./searchParamsState.js";


export const SearchQueryStates = Object.freeze(
  {
    "PRODUCT": "PRODUCT",
    "ORDER": "ORDER",
    "RETURN": "RETURN",
  }
);

function useSingleSearchQueryState(name, activeName, queryState) {
  const [state, setState] = useState(name === activeName && queryState ? queryState : "");
  const debouncedState = useDebounce(state, 500);
  return [name, [state, setState, debouncedState]];
}

function useSearchQueryState(initialSearchQuery) {
  const match = useMatchingRoute();

  // Get the searchQuery option for the matched route
  const activeSearchQuery = match?.route?.searchQuery;

  // Construct the lookup state object for the search queries of the different pages
  const lookupEntries = [];

  lookupEntries.push(useSingleSearchQueryState(
    SearchQueryStates.PRODUCT,
    activeSearchQuery,
    initialSearchQuery
  ));
  lookupEntries.push(useSingleSearchQueryState(
    SearchQueryStates.ORDER,
    activeSearchQuery,
    initialSearchQuery
  ));
  lookupEntries.push(useSingleSearchQueryState(
    SearchQueryStates.RETURN,
    activeSearchQuery,
    initialSearchQuery
  ));

  const lookups = Object.fromEntries(lookupEntries);

  const searchQueryStateFallback = useMemo(() => [undefined, undefined], []);
  const [searchQuery, setSearchQuery, debouncedSearchQuery] = activeSearchQuery in lookups ? lookups[activeSearchQuery] : searchQueryStateFallback;

  return [searchQuery, setSearchQuery, debouncedSearchQuery, activeSearchQuery];
}


const SearchQueryContext = createContext(null);

export function SearchQueryProvider({ children }) {
  const { searchParams, setSearchParams } = useSearchParamsState();

  const initialSearchQuery = useMemo(() => {
    return searchParams.get("q");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchQuery, setSearchQuery, debouncedSearchQuery, activeSearchQuery] = useSearchQueryState(initialSearchQuery);

  useEffect(() => {
    setSearchParams(params => {
      const copyParams = new URLSearchParams(params.toString());

      // Set the search query if it is defined, else delete it
      if (searchQuery) {
        copyParams.set("q", searchQuery);
      } else {
        copyParams.delete("q");
      }

      return copyParams;
    });
  }, [setSearchParams, searchQuery]);

  const value = {
    searchQuery,
    setSearchQuery,
    debouncedSearchQuery,
    activeSearchQuery,
  };

  return (
    <SearchQueryContext.Provider value={value}>
      {children}
    </SearchQueryContext.Provider>
  );
}

SearchQueryProvider.propTypes = {
  children: PropTypes.node,
};

export function useSearchQuery() {
  return useContext(SearchQueryContext);
}
