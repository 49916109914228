// eslint-disable-next-line import/order
import "./App.scss";

import { HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { Navigation, RangePicker, useDynamicNavigation, Button, Popover, Badge, Card, Input, Row, Space, Divider, Social, Menu, useHighlight } from "@sellevate-team/ui-components";
import { SettingsIcon } from "@sellevate-team/ui-components/dist/icons";
import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, useLocation, matchRoutes, useParams, NavLink, Link } from "react-router-dom";

import { useAuth } from "./auth/AuthContext.js";
import { useGtag } from "./client/hooks.js";
import NavBarStores from "./components/NavBarStores.jsx";
import { useDateRangeOptions } from "./dateRangeOptions.js";
import NotFound from "./pages/404.jsx";
import { useRoutes } from "./routes.js";
import { useDateRange, DateRangeProvider } from "./utils/dateRange";
import { useSearchQuery, SearchQueryProvider } from "./utils/searchQuery";
import { useStoreInfo } from "./utils/StoreInfoContext";

const LOCALSTORAGE_COLLAPSED_KEY = "isCollapsed";

function App() {
  const { t } = useTranslation();
  const { trackEvent } = useGtag();

  const { storeIds } = useParams();
  const {
    stores,
    availableStoreIds,
    selectedStoresExist
  } = useStoreInfo();

  // Route and filter query params
  const routes = useRoutes();
  const dateRangeOptions = useDateRangeOptions();

  let location = useLocation();
  const currentPath = location.pathname.split("/").slice(2).join("/");

  const { isLoading, user, isError, error } = useAuth(true);
  const { dynamicPathLabels } = useDynamicNavigation();
  const titleFormatter = title => `${title} - Sellevate`;
  const {
    menuItems,
    breadcrumbs,
    flatRoutes,
  } = Navigation.useNavigationSetup({
    routes,
    extraPathLabels: dynamicPathLabels,
    pathname: currentPath,
    MenuLink: NavLink,
    BreadcrumbLink: Link,
    titleFormatter,
    applyTitle: true,
  });

  const breadcrumbControlsHighlight = useHighlight("breadcrumb-controls", {}, {
    style: {
      borderRadius: "var(--border-radius)",
      padding: "5px",
      margin: "-5px",
    }
  });

  if (isLoading) {
    return <span>{t("Loading...")}</span>;
  }

  if (isError) {
    // The user could not be retrieved
    if (error.status === 403) {
      return <span>{t("Unauthenticated...")}</span>;
    }
    console.log(error);
    return <span>{t("Error...")}</span>;
  }

  let breadcrumbItems = [
    <Link key="/" to="." onClick={() => trackEvent("click", "navigation", "breadcrumbs home icon")}><HomeOutlined /></Link>
  ].concat(breadcrumbs);

  const process = route => ({
    ...route,
    path: route.includeSubPages ? route.path + "/*" : route.path,
    children: route.routes?.map(process),
  });

  // Map the routes to a new object where the path and children are replaced recursively using `process`
  const newRoutes = routes.map(process);

  const routeMatches = matchRoutes(newRoutes, location, `/${storeIds}`);

  let selectedKeys = routeMatches?.map(match => match.pathnameBase.replace(/^\/+|\/+$/g, ""));
  selectedKeys = selectedKeys || [];

  return (
    <SearchQueryProvider>
      <DateRangeProvider dateRanges={dateRangeOptions}>
        <Navigation
          logoContext={t("Store Management")}
          navBarContent={<NavBarStores
            stores={stores.isLoading ? [] : stores.data}
            selectedStoreIds={storeIds === "all" ? availableStoreIds : storeIds.split("+")}
            availableStoreIds={availableStoreIds}
            selectedStoresExist={selectedStoresExist}
            currentPath={currentPath}
            onOpenChange={bool => bool && trackEvent("click", "navigation", "open store menu")}
            onSelectAll={() => trackEvent("click", "navigation", "select all stores")}
          />}
          onLogoClick={() => trackEvent("click", "navigation", "open logo menu")}
          onProductsIconClick={() => trackEvent("click", "navigation", "open products icon menu")}
          siderCollapsedOnChange={b => {
            trackEvent("click", "navigation", "toggle sider menu");
            localStorage.setItem(LOCALSTORAGE_COLLAPSED_KEY, b);
          }}
          siderDefaultCollapsed={localStorage.getItem(LOCALSTORAGE_COLLAPSED_KEY) === "true"}
          navBarContentRight={<NavLink className="settings-header-icon" to={"./settings"}><Button type="text" icon={<SettingsIcon />} /></NavLink>}
          siderMenuItems={menuItems}
          siderHelpLabel={t("Help")}
          siderFooter={
            <>
              <div>
                <Menu selectable={false} items={[
                  {
                    label: t("Contact"),
                    key: "contact",
                    icon: <Social.SOCIALS.whatsapp.icon />,
                    onClick: () => window.open(Social.SOCIALS.whatsapp.url),
                  }
                ]}
                />
              </div>
              <div className="nav-footer">
                <span></span>
                <Social className="social-icons" />
              </div>
            </>
          }
          currentPath={currentPath}
          routeMatches={routeMatches}
          selectedKeys={selectedKeys}
          breadcrumbs={breadcrumbItems}
          breadcrumbRight={(
            <span
              {...breadcrumbControlsHighlight?.props}
              style={{ display: "inline-block", ...breadcrumbControlsHighlight?.props?.style }}
            >
              <Space><SearchFilter /><DateRangePicker /></Space>
            </span>
          )}
          userMenu={`${user.firstName} ${user.lastName}`}>
          {selectedStoresExist ? (
            <Routes>
              {flatRoutes.filter(r => r.component).map(r => (
                <Route key={r.path} path={r.includeSubPages ? r.path + "/*" : r.path} element={r.component()} />
              ))}
              <Route path="*" element={<NotFound type="home" />} />
            </Routes>
          ) : (
            <Row justify={"space-around"}>
              <Card title={t("Invalid store selected")}>
                <Space direction="vertical">
                  <div>{t("Click on the store selection menu in the top left corner to select a store.")}</div>
                  <Link to={"/all"}><Button >{t("Select all stores")}</Button></Link>
                </Space>
                <Divider />
                <Space direction="vertical">
                  <div>{t("Haven't connected a store yet? Then use the button below.")}</div>
                  <Link to={"/all/settings/stores/new"} ><Button >{t("Connect store")}</Button></Link>
                </Space>
              </Card>
            </Row>
          )}
        </Navigation>
      </DateRangeProvider>
    </SearchQueryProvider>
  );
}

export default App;


function SearchPopover({ value, onChange }) {
  const { t } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
    // Add a small delay, before focussing the input, so the popover can be opened
    setTimeout(() => {
      inputRef?.current?.focus({
        cursor: "end",
      });
    }, 10);
  }, [inputRef]);

  return (
    <Input
      allowClear
      placeholder={t("Search...")}
      value={value}
      onChange={onChange}
      ref={inputRef} />
  );
}

SearchPopover.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

function SearchFilter() {
  const { searchQuery, setSearchQuery, activeSearchQuery } = useSearchQuery();
  const { trackEvent } = useGtag();
  if (searchQuery === undefined) {
    return null;
  }

  return (
    <Popover content={<SearchPopover value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />} trigger="click" placement="bottom" destroyTooltipOnHide>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <Badge dot={searchQuery.length > 0} color="var(--accent-color)">
        <Button icon={<SearchOutlined />} style={{ boxShadow: "0px 2px var(--light-background-color" }}
          onClick={() => trackEvent("click", "navigation", `${activeSearchQuery}: open search filter`)}
        />
      </Badge>
    </Popover>
  );
}


function DateRangePicker() {
  const { trackEvent } = useGtag();
  const { dateRange, setDateRange, dateRanges, activeDateRange } = useDateRange();
  if (!dateRange || !dateRange[0] || !dateRange[1]) {
    return null;
  }
  return (
    <RangePicker
      dateRanges={dateRanges}
      value={dateRange}
      onChange={setDateRange}
      onOpenChange={b => b && trackEvent("click", "navigation", `${activeDateRange}: date range picker open`)}
      onPreviousClick={() => trackEvent("click", "navigation", `${activeDateRange}: date range picker previous`)}
      onNextClick={() => trackEvent("click", "navigation", `${activeDateRange}: date range picker next`)}
    />
  );
}
