import { ArrowUpOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import styles from "./PercentCompare.module.scss";


export default function PercentCompare({ from, to, precision, flip }) {
  const classList = [styles["percent-compare"]];
  let change = (to - from) / from * 100;
  let changeStr = change.toFixed(precision ? precision : (Math.abs(change) >= 100 ? 0 : 2));

  if (from === 0 && to === 0) {
    change = 0;
    changeStr = "-";
  } else if (Number.isNaN(change)) {
    change = 0;
    changeStr = "-";
  } else if (!Number.isFinite(change)) {
    change = 1;
    changeStr = <ArrowUpOutlined />;
  }

  if (flip && change < 0 || !flip && change > 0) {
    classList.push(styles["positive"]);
  } else if (change === 0) {
    classList.push(styles["zero"]);
  } else {
    classList.push(styles["negative"]);
  }

  return (
    <span className={classList.join(" ")}>
      {changeStr}%
    </span>
  );
}

PercentCompare.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  precision: PropTypes.number,
  flip: PropTypes.bool,
};

PercentCompare.defaultProps = {
  precision: undefined,
  flip: false,
};
