import { FilterOutlined } from "@ant-design/icons";
import { Table, Button, Popover, Segmented, Card, Responsive, ImageTitle, Empty, Row, Radio, Statistic, Typography, Space, Col, useHighlight } from "@sellevate-team/ui-components";
import { D3Bar, D3Line, Legend } from "@sellevate-team/ui-components/dist/charts";
import { ChevronDownIcon } from "@sellevate-team/ui-components/dist/icons";
import { ShuffleBlur } from "@sellevate-team/ui-components/dist/permissions";
import { cls } from "@sellevate-team/ui-components/dist/utils";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAuth } from "../auth/AuthContext";
import { useGtag, useStoreDashboard } from "../client/hooks";
import ChartTitle from "../components/ChartTitle.jsx";
import CustomTooltip from "../components/CustomTooltip.jsx";
import ExpiringStoreCredentialsAlert from "../components/ExpiringStoreCredentialsAlert.jsx";
import InfoButton from "../components/InfoButton.jsx";
import InvalidStoreCredentialsAlert from "../components/InvalidStoreCredentialsAlert.jsx";
import NoStoreAlert from "../components/NoStoreAlert.jsx";
import PercentCompare from "../components/PercentCompare.jsx";
import NoPermissionEmpty from "../components/permissions/NoPermissionEmpty.jsx";
import { UpsellCard } from "../components/permissions/UpsellCard.jsx";
import PlotTypeSelection from "../components/PlotTypeSelection.jsx";
import { useDateRange } from "../utils/dateRange";
import { useSearchParamsState, usePageSearchParams } from "../utils/searchParamsState";
import { useStoreInfo } from "../utils/StoreInfoContext";
import { createRandomGenerator, shuffle } from "../utils/utils";
import { toPercentage, toPrice } from "../utils/valueProcessing";

import { StatusTag } from "./OrderList.jsx";
import { HandledTag } from "./ReturnList.jsx";

import styles from "./Dashboard.module.scss";


function GroupByMenu({ value, onChange }) {
  const { t } = useTranslation();

  const popoverContent = (
    <Segmented options={[
      { value: "", label: t("None") },
      // eslint-disable-next-line i18next/no-literal-string
      { value: "store", label: t("Store") },
      // eslint-disable-next-line i18next/no-literal-string
      { value: "country", label: t("Country") },
    ]} onChange={onChange} value={value} />
  );

  return (
    <Popover content={popoverContent} title={t("Group by")} trigger="click" placement="bottomRight" arrowPointAtCenter>
      <Button icon={<FilterOutlined />} />
    </Popover>
  );
}
GroupByMenu.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};


function HistoryChart({ series, loading, fromDate, toDate, cumulative, onCumulativeDisabled }) {
  const id = useId();
  const { permissions } = useAuth();
  const { t } = useTranslation();
  const { selectedStores } = useStoreInfo();
  const { trackEvent } = useGtag();
  const [selection, setSelection] = useState("revenue");

  const handleSelection = (value) => {
    setSelection(value);
    onCumulativeDisabled(["averageOrderValue", "stock", "bestOffer"].includes(value));
  };

  const COLORS = ["#3d25a8", "#37c889", "#ffa900", "#3cd9e5", "#ff5b00", "#ff00ff", "pink"];

  const translationLookup = {
    revenue: t("Revenue"),
    sold: t("Sold"),
    orders: t("Orders"),
    averageOrderValue: t("Average order value"),
    stock: t("Stock"),
    bestOffer: t("Buy box"),
    returns: t("Returns"),
    returnsSum: t("Return amount"),
  };

  const priceFormatter = (value, domain) => domain ? toPrice(value, domain[1] - domain[0] > 100) : toPrice(value);
  const formatterLookup = {
    revenue: priceFormatter,
    averageOrderValue: priceFormatter,
    bestOffer: value => toPercentage(value, 0),
    returnsSum: priceFormatter,
  };

  const randomGen = createRandomGenerator(id + fromDate.toLocaleDateString() + toDate.toLocaleDateString());

  const chartSeries = series.map((serie, i) => ({
    id: serie.id,
    label: selectedStores[serie.id]?.name || serie.id,
    color: COLORS[i],
    data: serie.data.map(d => ({
      x: new Date(d.date),
      ...d,
      averageOrderValue: d.orders ? d.revenue / d.orders : 0,
    })).map(d => ({
      ...d,
      ...(permissions.orders ? {} : Object.fromEntries(Object.keys(translationLookup).map(key => ([key, d[key] === null ? null : parseFloat(shuffle(d[key], randomGen))])))),
    })),
  }));

  const D3Chart = selection === "sold" ? D3Bar : D3Line;

  const noDataComponent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <>
      <Row justify="space-between">
        <Radio.Group value={selection} onChange={e => { handleSelection(e.target.value); trackEvent("click", "dashboard", "chart switch y-property"); }} buttonStyle="solid">
          {/* eslint-disable i18next/no-literal-string */}
          <Radio.Button value="revenue">{t("Revenue")}</Radio.Button>
          <Radio.Button value="sold">{t("Sold")}</Radio.Button>
          <Radio.Button value="orders">{t("Orders")}</Radio.Button>
          <Radio.Button value="averageOrderValue" disabled={cumulative}>{t("Average order value")}</Radio.Button>
          <Radio.Button value="stock" disabled={cumulative}>{t("Stock")}</Radio.Button>
          <Radio.Button value="bestOffer" disabled={cumulative}>{t("Buy box")}</Radio.Button>
          <Radio.Button value="returns">{t("Returns")}</Radio.Button>
          <Radio.Button value="returnsSum">{t("Return amount")}</Radio.Button>
          {/* eslint-enable i18next/no-literal-string */}
        </Radio.Group>
      </Row>
      <ShuffleBlur limited={!permissions.orders} upsellCard={<UpsellCard />}>
        <D3Chart
          className={styles["history-chart"]}
          series={chartSeries}
          tooltip={<CustomTooltip yProperty={selection} keys={Object.keys(translationLookup)} translationLookup={translationLookup} formatterLookup={formatterLookup} />}
          legend={<Legend />}
          chartPaddingPercentage={0.2}
          margin={{ top: 24, right: 24, bottom: 24, left: 60 }}
          transitionFromZero
          // eslint-disable-next-line i18next/no-literal-string
          curve={selection === "bestOffer" ? "step" : "curved"}
          height={400}
          startAtZero
          showArea={selection === "bestOffer"}
          // eslint-disable-next-line i18next/no-literal-string
          xTickFormat={d => dayjs().isSame(d, "year") ? dayjs(d).format("L").slice(0, -5).replace(",", "") : dayjs(d).format("L")}
          yTickFormat={formatterLookup[selection] ?? (value => value)}
          tickWidth={60}
          yProperty={selection}
          strokeWidth={2.5}
          indicatorRadius={5}
          hoverdotRadius={9}
          loading={loading}
          skipTicks
          noDataComponent={noDataComponent}
          fromDate={fromDate}
          toDate={toDate}
          cumulative={cumulative}
          raiseHoverdSerie
        />
      </ShuffleBlur>
    </>
  );
}

HistoryChart.propTypes = {
  series: PropTypes.array,
  loading: PropTypes.bool,
  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
  cumulative: PropTypes.bool,
  onCumulativeDisabled: PropTypes.func,
};


function Dashboard() {
  const { permissions } = useAuth();
  const { t } = useTranslation();
  const { storeIds } = useStoreInfo();
  const { fromStr, toStr } = useDateRange();
  const { trackEvent } = useGtag();
  const [groupBy, setGroupBy] = useState("");
  const [cumulative, setCumulative] = useState(false);
  const [cumulativeDisabled, setCumulativeDisabled] = useState(false);
  const { isPreviousData, isLoading, data, isError, error, isFetching } = useStoreDashboard(storeIds, fromStr, toStr, groupBy);
  const isLoadingNewData = (isPreviousData && isFetching) || isLoading;
  const { currentPageParams } = useSearchParamsState();
  const DEFAULT_ACTIVE_TAB_KEY = "orders";
  const [activeTabKey, setActiveTabKey] = useState(currentPageParams["tab"] || DEFAULT_ACTIVE_TAB_KEY);

  usePageSearchParams({
    "tab": activeTabKey === DEFAULT_ACTIVE_TAB_KEY ? undefined : activeTabKey
  });

  const statisticsHighlight = useHighlight("dashboard-statistics", {}, {
    style: {
      borderRadius: "var(--border-radius)",
      padding: "5px",
      margin: "-5px",
    }
  });
  const historyHighlight = useHighlight("dashboard-history");
  const topProductsHighlight = useHighlight("dashboard-top-products");
  const ordersReturnsHighlight = useHighlight("dashboard-orders-returns");

  if (isError) {
    console.log(error);
    return <span>{t("Error...")}</span>;
  }

  const productColumns = [
    {
      title: t("Product"),
      dataIndex: "productImageMedium",
      key: "name",
      render: (image, product) => (
        <Link to={`../products/${product.id}`} onClick={() => trackEvent("click", "dashboard", "open product detail")}>
          <ImageTitle imageSrc={image} title={product.title} subTitle={product.ean} subTitlePrefix={t("EAN")} />
        </Link>
      )
    },
    {
      title: t("Stock"),
      dataIndex: "stock",
      key: "stock",
      width: 100,
      responsive: ["sm"],
    },
    {
      title: t("Revenue"),
      dataIndex: "revenue",
      key: "revenue",
      width: 100,
      render: text => <ShuffleBlur limited={!permissions.orders}>{toPrice(text)}</ShuffleBlur>
    },
    {
      dataIndex: "id",
      key: "actions",
      width: 64,
      render: id => <Link to={`../products/${id}`} onClick={() => trackEvent("click", "dashboard", "open product detail")}>
        <Button type="text" icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
      </Link>,
    },
  ];

  const orderColumns = [
    {
      title: t("Customer"),
      dataIndex: "billingDetails",
      key: "customerName",
      render: (billingDetails, order) => <Link to={`./orders/${order.id}`} onClick={() => trackEvent("click", "dashboard", "open order detail")}>
        {billingDetails?.firstName ? `${billingDetails?.firstName} ${billingDetails?.lastName}` : "-"}
      </Link>,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: status => <StatusTag status={status} />
    },
    {
      title: t("Date"),
      dataIndex: "orderDate",
      key: "date",
      responsive: ["sm"],
      render: text => dayjs(text).format("LL").slice(0, -5).replace(",", ""),
    },
    {
      title: t("Total price"),
      dataIndex: "totalPriceWithVat",
      key: "totalPrice",
      responsive: ["sm"],
      render: text => toPrice(text)
    },
    {
      dataIndex: "id",
      key: "actions",
      width: 64,
      render: id => <Link to={`./orders/${id}`} onClick={() => trackEvent("click", "dashboard", "open order detail")}>
        <Button type="text" icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
      </Link>,
    },
  ];

  const returnColumns = [
    {
      title: t("Customer"),
      dataIndex: "customerName",
      key: "customerName",
      render: (_, { billingFirstName, billingLastName, id }) => <Link to={`./returns/${id}`} onClick={() => trackEvent("click", "dashboard", "open return detail")}>
        {billingFirstName ? `${billingFirstName} ${billingLastName}` : "-"}
      </Link>,
    },
    {
      title: t("Handled"),
      dataIndex: "handled",
      key: "handled",
      render: handled => <HandledTag value={handled} />
    },
    {
      title: t("Date"),
      dataIndex: "returnDate",
      key: "date",
      responsive: ["sm"],
      render: text => dayjs(text).format("LL").slice(0, -5).replace(",", ""),
    },
    {
      title: t("Total price"),
      dataIndex: "totalPriceWithVat",
      key: "totalPrice",
      responsive: ["sm"],
      render: text => toPrice(text)
    },
    {
      dataIndex: "id",
      key: "actions",
      width: 64,
      render: id => <Link to={`./returns/${id}`} onClick={() => trackEvent("click", "dashboard", "open return detail")}>
        <Button type="text" icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
      </Link>,
    },
  ];

  const statistics = [
    { component: <Statistic className={styles["statistic"]} loading={isLoadingNewData} title={t("Revenue incl. VAT")} value={toPrice(data?.statistics.revenue)} formatter={value => <ShuffleBlur limited={!permissions.orders}>{value}</ShuffleBlur>} suffix={<PercentCompare from={data?.previousStatistics.revenue} to={data?.statistics.revenue} />} /> },
    { component: <Statistic className={styles["statistic"]} loading={isLoadingNewData} title={t("Number of sales")} value={data?.statistics.nrSold} formatter={value => <ShuffleBlur limited={!permissions.orders}>{value}</ShuffleBlur>} suffix={<PercentCompare from={data?.previousStatistics.nrSold} to={data?.statistics.nrSold} />} /> },
    // TODO: Decide on the metrics
    // { component: <Statistic title={t("Active products")} value={data?.statistics.activeProducts} /> },
    { component: <Statistic className={styles["statistic"]} loading={isLoadingNewData} title={t("Average order value")} value={toPrice(data?.statistics.averageOrderValue)} formatter={value => <ShuffleBlur limited={!permissions.orders}>{value}</ShuffleBlur>} suffix={<PercentCompare from={data?.previousStatistics.averageOrderValue} to={data?.statistics.averageOrderValue} />} /> },
    { component: <Statistic className={styles["statistic"]} loading={isLoadingNewData} title={t("Number of returns")} value={data?.statistics.returns} formatter={value => <ShuffleBlur limited={!permissions.orders}>{value}</ShuffleBlur>} suffix={<PercentCompare from={data?.previousStatistics.returns} to={data?.statistics.returns} flip />} /> },
    { component: <Statistic className={styles["statistic"]} loading={isLoadingNewData} title={t("Return amount")} value={toPrice(data?.statistics.returnsSum)} formatter={value => <ShuffleBlur limited={!permissions.orders}>{value}</ShuffleBlur>} suffix={<PercentCompare from={data?.previousStatistics.returnsSum} to={data?.statistics.returnsSum} flip />} /> },
  ];

  if (groupBy === "country") {
    statistics.push({ component: <Statistic className={styles["statistic"]} loading={isLoadingNewData} title={t("Current stock")} value={data?.statistics?.totalStock ? data?.statistics?.totalStock : 0} formatter={value => <ShuffleBlur limited={!permissions.orders}>{value}</ShuffleBlur>} /> });
  } else {
    statistics.push({ component: <Statistic className={styles["statistic"]} loading={isLoadingNewData} title={t("Buy box percentage")} value={toPercentage(data?.statistics.bestOfferAvg, 0)} suffix={<PercentCompare from={data?.previousStatistics.bestOfferAvg} to={data?.statistics.bestOfferAvg} />} /> });
  }

  const tabList = [
    {
      key: "orders",
      tab: t("Latest orders"),
    },
    {
      key: "returns",
      tab: t("Latest returns"),
    },
  ];

  const tabContentList = {
    orders: (<Table
      className={styles["dashboard-table"]}
      rowKey="id"
      columns={orderColumns}
      dataSource={data?.latestOrders}
      pagination={false}
      loading={isLoadingNewData}
      showSorterTooltip={false}
      footer={() => data?.latestOrders?.length > 0 ? (
        <Link to={"../orders"} onClick={() => trackEvent("click", "dashboard", "show more orders")}>
          {t("Show more")}
        </Link>
      ) : undefined}
      components={{
        body: {
          wrapper: !permissions.orders ? () => (
            <tbody className="ant-table-tbody">
              <tr className="ant-table-placeholder">
                <td className="ant-table-cell" colSpan="100">
                  <NoPermissionEmpty subject={t("orders")} includeButton />
                </td>
              </tr>
            </tbody>
          ) : undefined,
        }
      }} />),
    returns: (<Table
      className={styles["dashboard-table"]}
      rowKey="id"
      columns={returnColumns}
      dataSource={data?.latestReturns}
      pagination={false}
      loading={isLoadingNewData}
      showSorterTooltip={false}
      // eslint-disable-next-line i18next/no-literal-string
      footer={() => data?.latestReturns?.length > 0 ? (
        <Link to={"../returns"} onClick={() => trackEvent("click", "dashboard", "show more returns")}>
          {t("Show more")}
        </Link>
      ) : undefined}
      components={{
        body: {
          wrapper: !permissions.orders ? () => (
            <tbody className="ant-table-tbody">
              <tr className="ant-table-placeholder">
                <td className="ant-table-cell" colSpan="100">
                  <NoPermissionEmpty subject={t("returns")} includeButton />
                </td>
              </tr>
            </tbody>
          ) : undefined,
        }
      }} />),
  };

  return (
    <Responsive.Space direction="vertical">
      <Typography.Title style={{ marginTop: "27px" }}>
        {t("Dashboard")}
        <InfoButton style={{ marginLeft: "8px" }} revertColors />
      </Typography.Title>
      <NoStoreAlert />
      <InvalidStoreCredentialsAlert />
      <ExpiringStoreCredentialsAlert />
      <div {...statisticsHighlight?.props} className={cls(statisticsHighlight?.className, styles["statistic-cards"])} >
        {statistics.map((obj, i) => (<Card key={i}>{obj.component}</Card>))}
      </div>
      <Card
        title={<ChartTitle title={t("History")} fromDate={data?.history[0]?.data[0]?.date} toDate={data?.history[0]?.data?.at(-1)?.date} />}
        extra={(
          <Space>
            <PlotTypeSelection value={cumulative} onChange={bool => { setCumulative(bool); trackEvent("click", "dashboard", "chart cumulative switch"); }} cumulativeDisabled={cumulativeDisabled} />
            <GroupByMenu value={groupBy} onChange={val => { setGroupBy(val); trackEvent("click", "dashboard", "chart group by menu"); }} />
          </Space>
        )}
        {...historyHighlight?.props}
      >
        <HistoryChart
          series={data?.history || []}
          loading={isLoadingNewData}
          fromDate={new Date(fromStr)}
          toDate={new Date(toStr)}
          cumulative={cumulative}
          onCumulativeDisabled={setCumulativeDisabled} />
      </Card>
      <Responsive.Row>
        <Col flex={"1 1 500px"} style={{ width: "500px" }}>
          <Card title={t("Top products")} {...topProductsHighlight?.props} className={cls(topProductsHighlight?.className, styles["list-card"])}>
            <Table
              className={styles["dashboard-table"]}
              rowKey="id"
              columns={productColumns}
              dataSource={data?.topProducts}
              pagination={false}
              loading={isLoadingNewData}
              showSorterTooltip={false}
              footer={() => data?.topProducts?.length > 0 ? (
                <Link to={"../products"} onClick={() => trackEvent("click", "dashboard", "show more products")}>
                  {t("Show more")}
                </Link>
              ) : undefined} />
          </Card>
        </Col>
        <Col flex={"1 1 500px"} style={{ width: "500px" }}>
          <Card
            {...ordersReturnsHighlight?.props}
            className={cls(ordersReturnsHighlight?.className, styles["list-card"])}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={key => {
              setActiveTabKey(key);
              trackEvent("click", "dashboard", "switch orders/returns tabs");
            }}>
            {tabContentList[activeTabKey]}
          </Card>
        </Col>
      </Responsive.Row>
    </Responsive.Space>
  );
}

export default Dashboard;
