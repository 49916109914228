import { HomeOutlined, ShoppingCartOutlined, RollbackOutlined, ShopOutlined } from "@ant-design/icons";
import { constructNotification, Tooltip } from "@sellevate-team/ui-components";
import { BookmarkIcon, TrackIcon, TelescopeIcon, ScannerIcon, CalculatorIcon, StockIcon, LockIcon } from "@sellevate-team/ui-components/dist/icons";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

import { useAuth } from "./auth/AuthContext.js";
import { useGtag } from "./client/hooks.js";
import { DashboardHelpDescription } from "./help/Dashboard.jsx";
import { OrderDetailHelpDescription } from "./help/OrderDetail.jsx";
import { OrderListHelpDescription } from "./help/OrderList.jsx";
import { ProductDetailHelpDescription } from "./help/ProductDetail.jsx";
import { ProductListHelpDescription } from "./help/ProductList.jsx";
import { ReturnDetailHelpDescription } from "./help/ReturnDetail.jsx";
import { ReturnListHelpDescription } from "./help/ReturnList.jsx";
import Dashboard from "./pages/Dashboard.jsx";
import OrderDetail from "./pages/OrderDetail.jsx";
import OrderList from "./pages/OrderList.jsx";
import ProductDetail from "./pages/ProductDetail.jsx";
import ProductList from "./pages/ProductList.jsx";
import ReturnDetail from "./pages/ReturnDetail.jsx";
import ReturnList from "./pages/ReturnList.jsx";
import Settings from "./pages/Settings";
import { DateRangeStates } from "./utils/dateRange.js";
import { SearchQueryStates } from "./utils/searchQuery.js";

export function useRoutes() {
  const { t } = useTranslation();
  const { trackEvent } = useGtag();
  const { permissions } = useAuth();

  return [
    {
      type: "group",
      label: <><Tooltip title={t("Store Management")} placement="right" overlayStyle={{ paddingLeft: "40px" }}><ShopOutlined /></Tooltip><span>{t("Store Management")}</span></>,
      className: "feature-group"
    },
    {
      path: "",
      label: t("Dashboard"),
      exact: true,
      icon: () => <HomeOutlined />,
      component: () => <Dashboard />,
      dateRange: DateRangeStates.PRODUCT,
      onHelp: options => constructNotification("dashboard-help-notification", {
        message: t("Dashboard"),
        description: <DashboardHelpDescription />,
      }, options),
    },
    {
      path: "products",
      label: t("Products"),
      icon: () => <StockIcon />,
      component: () => <ProductList />,
      dateRange: DateRangeStates.PRODUCT,
      searchQuery: SearchQueryStates.PRODUCT,
      onHelp: options => constructNotification("product-list-help-notification", {
        message: t("Products"),
        description: <ProductListHelpDescription />,
      }, options),
      routes: [
        {
          path: ":productId",
          hidden: true,
          component: () => <ProductDetail />,
          dateRange: DateRangeStates.PRODUCT,
          onHelp: options => constructNotification("product-detail-help-notification", {
            message: t("Product page"),
            description: <ProductDetailHelpDescription />,
          }, options),
        }
      ]
    },
    {
      path: "orders",
      label: t("Orders"),
      icon: () => <ShoppingCartOutlined />,
      component: () => <OrderList />,
      dateRange: DateRangeStates.ORDER,
      searchQuery: SearchQueryStates.ORDER,
      onHelp: options => constructNotification("order-list-help-notification", {
        message: t("Orders"),
        description: <OrderListHelpDescription />,
      }, options),
      routes: [
        {
          path: ":orderId",
          hidden: true,
          component: () => <OrderDetail />,
          dateRange: DateRangeStates.ORDER,
          onHelp: options => constructNotification("order-detail-help-notification", {
            message: t("Order page"),
            description: <OrderDetailHelpDescription />,
          }, options),
        }
      ]
    },
    {
      path: "returns",
      label: t("Returns"),
      icon: () => <RollbackOutlined />,
      component: () => <ReturnList />,
      dateRange: DateRangeStates.RETURN,
      searchQuery: SearchQueryStates.RETURN,
      onHelp: options => constructNotification("return-list-help-notification", {
        message: t("Returns"),
        description: <ReturnListHelpDescription />,
      }, options),
      routes: [
        {
          path: ":returnId",
          hidden: true,
          component: () => <ReturnDetail />,
          dateRange: DateRangeStates.RETURN,
          onHelp: options => constructNotification("return-detail-help-notification", {
            message: t("Return page"),
            description: <ReturnDetailHelpDescription />,
          }, options),
        }
      ]
    },
    {
      type: "group",
      label: <><Tooltip title={t("Product Research")} placement="right" overlayStyle={{ paddingLeft: "40px" }}><TelescopeIcon /></Tooltip><span>{t("Product Research")}</span></>,
      className: "feature-group"
    },
    {
      path: "dashboard",
      label: t("Dashboard"),
      icon: () => <HomeOutlined />,
      permission: permissions.researchDashboard,
      onClick: () => {
        trackEvent("click", "navigation", "product research dashboard link");
        window.location.href = `${process.env.REACT_APP_AUTH_URL}/extension?page=/`;
      },
    },
    {
      path: "search",
      label: t("Search"),
      icon: () => <ScannerIcon />,
      permission: permissions.researchSearch,
      onClick: () => {
        trackEvent("click", "navigation", "product research search link");
        window.location.href = `${process.env.REACT_APP_AUTH_URL}/extension?page=/search`;
      },
    },
    {
      path: "follow",
      label: t("Follow"),
      icon: () => <TrackIcon />,
      permission: permissions.researchTracker,
      onClick: () => {
        trackEvent("click", "navigation", "product research tracker link");
        window.location.href = `${process.env.REACT_APP_AUTH_URL}/extension?page=/follow`;
      },
    },
    {
      path: "calculate",
      label: t("Calculate"),
      icon: () => <CalculatorIcon />,
      permission: permissions.researchCalculator,
      onClick: () => {
        trackEvent("click", "navigation", "product research calculator link");
        window.location.href = `${process.env.REACT_APP_AUTH_URL}/extension?page=/calculate`;
      },
    },
    {
      path: "settings",
      hidden: true,
      label: t("Settings"),
      includeSubPages: true,
      icon: () => <BookmarkIcon />,
      component: () => <Settings />,
      onHelp: ({ handleClose }) => {
        notification.info({
          key: "settings-help-notification",
          message: t("Settings"),
          description: t("The settings page allows you to change your language; connect new stores and manage existing stores; and check your Sellevate account."),
          placement: "bottomRight",
          duration: null,
          onClose: handleClose,
        });
        return () => notification.close("settings-help-notification");
      },
    }
  ].filter(route => !route.permission || !route.permission.isHidden())
    .map(route => {
      if (route.permission && route.permission.isLocked()) {
        route.extraIcon = <LockIcon />;
      }
      return route;
    });
}


export function useProductItems() {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return [
    {
      label: t("Store Management"),
      href: process.env.REACT_APP_SITE_URL,
      key: "0",
    },
    {
      label: t("Product Research"),
      href: `${process.env.REACT_APP_AUTH_URL}/extension`,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: t("Account"),
      href: process.env.REACT_APP_AUTH_URL,
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: t("Logout"),
      onClick: () => logout(),
      key: "logout",
    },
  ];
}
