import { NotificationCarousel } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";

export function ReturnListHelpDescription({ ...props }) {
  const { t } = useTranslation("help", { keyPrefix: "returnList" });
  return (
    <NotificationCarousel highlights={["return-list-list", "breadcrumb-controls"]} {...props}>
      <div>
        {t("The returns page shows the orders that have been returned by your customers. Check the status of the return along with other important information. More detailed information is shown when you click on a return.")}
      </div>
      <div>
        {t("Use the period selection to see returns from other periods. Search for returns using the search button.")}
      </div>
    </NotificationCarousel>
  );
}
