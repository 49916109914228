import { Button, Card } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../auth/AuthContext";


export function UpsellCard({ ...props }) {
  const { user } = useAuth();
  const { t } = useTranslation();

  let upsellText = t("Purchase a subscription now for the full version!");
  if (user?.subscription?.planType === "PD") {
    upsellText = t("Upgrade your subscription now for the full version!");
  }
  if (user?.canStartTrial) {
    upsellText = t("Try the free trial now!");
  }

  return (
    <Card title={t("No permission")} {...props}>
      <p>{upsellText}</p>
      <Button type="primary" href={`${process.env.REACT_APP_AUTH_URL}/`} target="_self">{t("View subscriptions")}</Button>
    </Card>
  );
}
