import { ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Button } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useStoreInfo } from "../utils/StoreInfoContext";



export default function NoStoreAlert() {
  const { availableStoreIds, loadingStores } = useStoreInfo();
  const { t } = useTranslation();

  if (availableStoreIds.length > 0 || loadingStores) {
    return null;
  }

  return (
    <Alert type="warning"
      showIcon
      message={t("No stores connected yet. Use the button on the right to connect a store now! Of course we handle the data securely.")}
      action={
        <Link to={"/all/settings/stores/new"}><Button icon={<ArrowRightOutlined />} /></Link>
      }
    />
  );
}
