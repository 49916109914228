import { NotificationCarousel } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";

export function DashboardHelpDescription({ ...props }) {
  const { t } = useTranslation("help", { keyPrefix: "dashboard" });
  return (
    <NotificationCarousel highlights={[undefined, "store-selection", "breadcrumb-controls", "dashboard-statistics", "dashboard-history", "dashboard-history", "dashboard-top-products", "dashboard-orders-returns"]} {...props}>
      <div>
        {t("This is your Store Management dashboard. Here you can see at a glance how your store(s) are performing.")}
      </div>
      <div>
        {t("Use the highlighted store selection menu to choose between different stores. Select multiple stores to get an overall picture and compare the stores.")}
      </div>
      <div>
        {t("At the top right, select the period over which the data will be calculated. Use the suggestions to quickly switch between different periods.")}
      </div>
      <div>
        {t("At the top of the page you see the blocks of statistics. These show at a glance how your store is doing. The percentages in green or red show how the statistic is doing compared to the previous period.")}
      </div>
      <div>
        {t("The history chart provides a more detailed view of the various metrics. It is possible to track revenue, sales and other key values.")}
      </div>
      <div>
        {t("Use the buttons in the top right corner to adjust the graph settings. You can choose between an incremental and a cumulative view. There is also an option to group the data by store or country.")}
      </div>
      <div>
        {t("The highlighted block shows the five products with the most revenue in the selected period. Click on a product to see the full product page.")}
      </div>
      <div>
        {t("This is the list of the latest orders. This list immediately shows you which orders have been placed and by whom. You can also see the latest returns by clicking on the heading.")}
      </div>
    </NotificationCarousel>
  );
}
