export var Permission = Object.freeze(
  {
    "FULL": 0b00000001,
    "LIMITED": 0b00000010,
    "LOCKED": 0b00000100,
    "HIDDEN": 0b00001000,
  });

export const ACTIVE_PERMISSION = Permission.FULL | Permission.LIMITED;

export class FeaturePermission {
  constructor(permission) {
    this.permission = permission;
  }

  hasPermission(value, permission) {
    return (value & permission) > 0;
  }


  isFull() {
    return this.hasPermission(this.permission, Permission.FULL);
  }

  isActive() {
    return this.hasPermission(this.permission, ACTIVE_PERMISSION);
  }

  isLimited() {
    return this.hasPermission(this.permission, Permission.LIMITED);
  }

  isHidden() {
    return this.hasPermission(this.permission, Permission.HIDDEN);
  }

  isLocked() {
    return this.hasPermission(this.permission, Permission.LOCKED);
  }

}


export function convertToPermission(value, defaultValue = Permission.HIDDEN) {
  switch (value) {
    case "full":
      return new FeaturePermission(Permission.FULL);
    case "limited":
      return new FeaturePermission(Permission.LIMITED);
    case "locked":
      return new FeaturePermission(Permission.LOCKED);
    case "hidden":
      return new FeaturePermission(Permission.HIDDEN);
    default:
      return new FeaturePermission(defaultValue);
  }
}

/**
 * 
 * @param {Array<FeaturePermision>} featurePermissions The list of feature permissions to combine
 * @returns {FeaturePermission} The combined feature permission object
 */
export function combinePermissions(featurePermissions) {
  if (featurePermissions.some(permission => permission.isFull())) return new FeaturePermission(Permission.FULL);
  if (featurePermissions.some(permission => permission.isLimited())) return new FeaturePermission(Permission.LIMITED);
  if (featurePermissions.some(permission => permission.isLocked())) return new FeaturePermission(Permission.LOCKED);
  return new FeaturePermission(Permission.HIDDEN);
}
