/* eslint-disable i18next/no-literal-string */
import Cookies from "js-cookie";

import { isObject } from "../utils/utils";

function createApiUrl(queryKey) {
  const searchParams = new URLSearchParams();
  const mappedPaths = queryKey.map(key => {
    if (Array.isArray(key)) {
      return key.join("+");
    }
    return key;
  });
  const url = `/api/${mappedPaths.filter(v => !(typeof v === "object")).join("/")}/`;

  const lastKey = queryKey.at(-1);
  if (isObject(lastKey)) {
    // If the last item is an object, add the values to the search params
    for (let [key, value] of Object.entries(lastKey)) {
      if (value === undefined || value === null) {
        continue;
      }
      const keyParam = key.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
      searchParams.set(keyParam, value);
    }
  }

  return {
    url,
    searchParams,
    searchParamsString: searchParams.toString(),
  };
}

async function get({ queryKey, url, searchParams }) {
  if (!url) {
    let searchParamsString;
    ({ url, searchParams, searchParamsString } = createApiUrl(queryKey));
    if (searchParamsString !== "") {
      url = `${url}?${searchParamsString}`;
    }
  }
  const response = await fetch(url);

  const isJson = response.headers.get("content-type")?.includes("application/json");
  const data = isJson ? await response.json() : null;

  // In development, delay the post request
  if (process.env.NODE_ENV === "development" && process.env.REACT_APP_SLOW_CLIENT) {
    await new Promise((resolve, reject) => {
      setTimeout(resolve, 500);
    });
  }

  // check for error response
  if (!response.ok) {
    const error = {
      status: response.status,
      data: data,
    };
    return Promise.reject(error);
  }

  return data;
}

async function request(method, url, data) {
  const response = await fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    }
  });

  const isJson = response.headers.get("content-type")?.includes("application/json");
  const respData = isJson ? await response.json() : null;

  // In development, delay the post request
  if (process.env.NODE_ENV === "development" && process.env.REACT_APP_SLOW_CLIENT) {
    await new Promise((resolve, reject) => {
      setTimeout(resolve, 1500);
    });
  }

  // check for error response
  if (!response.ok) {
    const error = {
      status: response.status,
      data: respData,
    };
    return Promise.reject(error);
  }

  return respData;
}


export default {
  createApiUrl,
  get,
  post: (url, data) => request("POST", url, data),
  patch: (url, data) => request("PATCH", url, data),
  put: (url, data) => request("PUT", url, data),
  delete: (url, data) => request("DELETE", url, data),
};
