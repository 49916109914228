import { BlankPage, Button } from "@sellevate-team/ui-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../auth/AuthContext.js";

export default function ChooseSubscription() {
  const { t } = useTranslation();
  const { user } = useAuth(true);
  const location = useLocation();

  // Redirect the user to the landing page, when subscription is not null
  if (user && user.subscription !== null && location.pathname === "/choose-subscription") {
    return <Navigate to="/" replace />;
  }

  return (
    <BlankPage inCard cardTitle={t("Choose a subscription")}>
      <p>
        {t("Use the powerful Sellevate tool now and manage your own stores from one place. Make choices based on clear data and graphs.")}
      </p>
      <p>
        {t("To start using Store Management, choose a subscription by clicking the button below.")}
      </p>
      {user?.canStartTrial && (
        <p>
          {t("Consider trying Sellevate for free, by starting the Free Trial subscription plan.")}
        </p>
      )}
      <Button type="primary" href={`${process.env.REACT_APP_AUTH_URL}/`} target="_self">{t("View subscriptions")}</Button>
    </BlankPage>
  );
}


export function SubscriptionRequired({ children }) {
  const { user } = useAuth();
  const location = useLocation();

  // Redirect the user to the choose-subscription page, when the subscription attribute is null
  if (user && user.subscription === null && location.pathname !== "/choose-subscription") {
    return <Navigate to="/choose-subscription" replace />;
  }

  return children;
}

SubscriptionRequired.propTypes = {
  children: PropTypes.node,
};
