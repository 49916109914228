import { ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Button } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useStoreInfo } from "../utils/StoreInfoContext";


export default function InvalidStoreCredentialsAlert() {
  const { storeIds, stores } = useStoreInfo();
  const { t } = useTranslation();

  let containsInvalidStoreCredentials = false;
  if (storeIds[0] === "all" && stores?.data?.find(s => !s?.credentials?.valid)) {
    containsInvalidStoreCredentials = true;
  } else if (stores?.data?.filter(s => storeIds.includes(s.id))?.find(s => !s?.credentials?.valid)) {
    containsInvalidStoreCredentials = true;
  }

  if (!containsInvalidStoreCredentials) {
    return null;
  }

  const invalidStores = stores?.data?.filter(s => !s?.credentials?.valid);

  return (
    <Alert type="error"
      showIcon
      message={t("The credentials of {{ count }} stores are expired. Use the button to renew the store credentials now!", { count: invalidStores.length })}
      action={
        <Link to={"/all/settings/stores/connected"}><Button icon={<ArrowRightOutlined />} /></Link>
      }
    />
  );
}
