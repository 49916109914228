/* eslint-disable i18next/no-literal-string */
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useResolvedPath, useSearchParams } from "react-router-dom";

import useMemoObject from "./hooks/useMemoObject";

const SearchParamsContext = createContext(null);

export function SearchParamsStateProvider({ children }) {
  const location = useLocation();
  const currentPath = location.pathname.split("/").slice(2).filter(p => p != "").join("/");

  const [searchParamsUrl, setSearchParamsUrl] = useSearchParams();

  // Create the global initial search params object
  const initialSearchParams = useMemo(() => {
    const params = new URLSearchParams();
    const setValue = key => {
      const value = searchParamsUrl.get(key);
      if (value !== null) {
        params.set(key, value);
      }
    };
    setValue("from");
    setValue("to");
    setValue("q");
    return params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchParamsState, setSearchParamsState] = useState(initialSearchParams);

  // Create the per page search params lookup object
  // Initialize the current page using the provided url search params
  const [pageSearchParams, setPageSearchParams] = useState({
    [currentPath]: Object.fromEntries(searchParamsUrl)
  });

  // Get the page params for the current page
  const currentPageParams = useMemo(() => {
    return pageSearchParams[currentPath] || {};
  }, [pageSearchParams, currentPath]);

  // Create a function for adding search params for a given page path
  function addPageSearchParams(path, params) {
    setPageSearchParams(prev => ({
      ...prev,
      [path]: params,
    }));
  }

  // Combine and memoize the global and page search params
  const combinedSearchParams = useMemo(() => {
    return new URLSearchParams({
      ...Object.fromEntries(searchParamsState),
      ...currentPageParams,
    });
  }, [searchParamsState, currentPageParams]);

  // Update the url search params, when the combined search params changes
  useEffect(() => {
    setSearchParamsUrl(combinedSearchParams, { replace: true });
  }, [setSearchParamsUrl, combinedSearchParams]);

  const value = {
    searchParams: combinedSearchParams,
    setSearchParams: setSearchParamsState,
    pageSearchParams,
    addPageSearchParams,
    currentPageParams,
  };

  return (
    <SearchParamsContext.Provider value={value}>
      {children}
    </SearchParamsContext.Provider>
  );
}

SearchParamsStateProvider.propTypes = {
  children: PropTypes.node,
};

export function useSearchParamsState() {
  return useContext(SearchParamsContext);
}

export function usePageSearchParams(params, to = "") {
  /**
   * Function for using url search params on a specific page
   */
  const resolved = useResolvedPath(to);
  const currentPath = resolved.pathname.split("/").slice(2).filter(p => p != "").join("/");
  const {
    addPageSearchParams,
  } = useSearchParamsState();

  const memoParams = useMemoObject(params);

  useLayoutEffect(() => {
    addPageSearchParams(currentPath, Object.fromEntries(Object.entries(memoParams).filter(([k, v]) => v !== undefined)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoParams]);
}
