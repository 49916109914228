import { ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Button } from "@sellevate-team/ui-components";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useStoreInfo } from "../utils/StoreInfoContext";

export default function ExpiringStoreCredentialsAlert() {
  const { storeIds, stores } = useStoreInfo();
  const { t } = useTranslation();

  let containsExpiringStoreCredentials = false;
  if (storeIds[0] === "all" && stores?.data?.find(s => dayjs().isAfter(dayjs(s?.credentials?.expirationNoticeAt)) && s?.credentials?.valid)) {
    containsExpiringStoreCredentials = true;
  } else if (stores?.data?.filter(s => storeIds.includes(s.id))?.find(s => dayjs().isAfter(dayjs(s?.credentials?.expirationNoticeAt)) && s?.credentials?.valid)) {
    containsExpiringStoreCredentials = true;
  }

  if (!containsExpiringStoreCredentials) {
    return null;
  }

  const expiringStores = stores?.data?.filter(s => dayjs().isAfter(dayjs(s?.credentials?.expirationNoticeAt)) && s?.credentials?.valid);

  return (
    <Alert type="warning"
      showIcon
      message={t("The credentials of {{ count }} stores are expiring. Use the button to renew the store credentials now!", { count: expiringStores.length })}
      action={
        <Link to={"/all/settings/stores/connected"}><Button icon={<ArrowRightOutlined />} /></Link>
      }
    />
  );
}
