import { Button, Responsive, Skeleton, Table, Tag, useHighlight } from "@sellevate-team/ui-components";
import { ChevronDownIcon } from "@sellevate-team/ui-components/dist/icons";
import { ListLayout } from "@sellevate-team/ui-components/dist/layouts";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import { useAuth } from "../auth/AuthContext";
import { useGtag, useOrderListInfinite } from "../client/hooks";
import NoStoreAlert from "../components/NoStoreAlert.jsx";
import NoPermissionEmpty from "../components/permissions/NoPermissionEmpty.jsx";
import { useDateRange } from "../utils/dateRange";
import { useSearchQuery } from "../utils/searchQuery";
import { useStoreInfo } from "../utils/StoreInfoContext";
import { toPrice } from "../utils/valueProcessing";


export function StatusTag({ className, style, status }) {
  const { t } = useTranslation();

  const lookup = {
    pending: {
      text: t("Pending"),
      color: "var(--ant-warning-color)",
    },
    on_hold: {
      text: t("On-Hold"),
      color: "var(--ant-warning-color)",
    },
    failed: {
      text: t("Failed"),
      color: "var(--ant-error-color",
    },
    cancelled: {
      text: t("Cancelled"),
      color: "var(--dark-text-color)",
    },
    processing: {
      text: t("Processing"),
      color: "var(--primary-color)",
    },
    completed: {
      text: t("Completed"),
      color: "var(--accent-color)",
    },
  };
  let data = status in lookup ? lookup[status] : { text: "-", color: "default" };
  let classList = [];
  if (className) classList.push(className);
  return <Tag style={style} className={classList.join(" ")} color={data.color}>{data.text}</Tag>;
}

StatusTag.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  status: PropTypes.string,
};

function OrderList() {
  const { t } = useTranslation();
  const { storeIds, selectedStores, selectedStoreIds } = useStoreInfo();
  const { fromStr, toStr } = useDateRange();
  const { debouncedSearchQuery } = useSearchQuery();
  const { permissions } = useAuth();
  const { trackEvent } = useGtag();
  const { data, error, fetchNextPage, hasNextPage, isLoading, isError } = useOrderListInfinite(storeIds, fromStr, toStr, debouncedSearchQuery);

  const listHighlight = useHighlight("order-list-list", { before: true });

  const columns = [
    {
      title: t("Customer"),
      dataIndex: "customerName",
      key: "customerName",
      render: (_, { billingDetails, id }) => <Link to={`./${id}`} onClick={() => trackEvent("click", "order list", "open order detail")}>
        {billingDetails.firstName ? `${billingDetails?.firstName} ${billingDetails?.lastName}` : "-"}
      </Link>,
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: status => <StatusTag status={status} />
    },
    {
      title: t("Date"),
      dataIndex: "orderDate",
      key: "date",
      responsive: ["sm"],
      render: text => dayjs(text).format("LLL"),
    },
    {
      title: t("Total price"),
      dataIndex: "totalPriceWithVat",
      key: "totalPrice",
      responsive: ["sm"],
      render: text => toPrice(text)
    },
    {
      title: t("Items"),
      dataIndex: "items",
      key: "nrItems",
      responsive: ["md"],
      render: items => items.length
    },
    {
      title: t("Country"),
      dataIndex: "billingDetails",
      key: "country",
      responsive: ["md"],
      render: billingDetails => billingDetails?.country || "-"
    },
    {
      title: t("Store"),
      dataIndex: "store",
      key: "store",
      width: 160,
      responsive: ["xl"],
      render: id => selectedStores[id]?.name,
      hide: selectedStoreIds.length < 2,
    },
    {
      dataIndex: "id",
      key: "actions",
      width: 64,
      render: id => <Link to={`./${id}`} onClick={() => trackEvent("click", "order list", "open order detail")}>
        <Button type="text" icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
      </Link>,
    },
  ].filter(c => !c.hide);


  if (isError) {
    console.log(error);
    return <span>{t("Error...")}</span>;
  }

  return (
    <Responsive.Space direction="vertical">
      <NoStoreAlert />
      <ListLayout sticky {...listHighlight?.props}>
        <InfiniteScroll
          style={{ overflow: "initial" }}
          dataLength={data?.pages.flatMap((group, i) => group.results).length || 0}
          next={() => fetchNextPage()}
          hasMore={isLoading ? true : hasNextPage}
          loader={<Skeleton title paragraph={false} active />}
        >
          <Table
            sticky
            rowKey="id"
            columns={columns}
            dataSource={data?.pages.flatMap((group, i) => group.results)}
            pagination={false}
            loading={isLoading}
            showSorterTooltip={false}
            components={{
              body: {
                wrapper: !permissions.orders ? () => (
                  <tbody className="ant-table-tbody">
                    <tr className="ant-table-placeholder">
                      <td className="ant-table-cell" colSpan="100">
                        <NoPermissionEmpty subject={t("orders")} includeButton />
                      </td>
                    </tr>
                  </tbody>
                ) : undefined,
              }
            }} />
        </InfiniteScroll>
      </ListLayout>
    </Responsive.Space>
  );
}

export default OrderList;
