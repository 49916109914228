import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Datalist, Table, ImageTitle, Statistic, Typography, useHighlight } from "@sellevate-team/ui-components";
import { ChevronDownIcon } from "@sellevate-team/ui-components/dist/icons";
import { DetailLayout } from "@sellevate-team/ui-components/dist/layouts";
import { hasFlag } from "country-flag-icons";
import flags from "country-flag-icons/react/3x2";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useParams } from "react-router-dom";

import { useAuth } from "../auth/AuthContext";
import { useGtag, useReturnDetail } from "../client/hooks";
import { useDynamicPathLabel } from "../utils/router";
import { useStoreInfo } from "../utils/StoreInfoContext";
import { toPrice } from "../utils/valueProcessing";

import NotFound from "./404.jsx";
import { HandledTag } from "./ReturnList.jsx";

import styles from "./ReturnDetail.module.scss";

function ReturnDetail() {
  const auth = useAuth();
  const { permissions } = auth;
  const { t } = useTranslation();
  const { storeIds } = useStoreInfo();
  const { returnId } = useParams();
  const { trackEvent } = useGtag();
  const { isLoading, isError, data, error } = useReturnDetail(storeIds, returnId);
  useDynamicPathLabel(data && `${data?.id} ${data?.billingFirstName && "-"} ${data?.billingFirstName} ${data?.billingLastName}`.trim());

  const statisticsHighlight = useHighlight("return-detail-statistics", {}, {
    style: {
      borderRadius: "var(--border-radius)",
      padding: "5px",
      margin: "-5px",
    }
  });

  const returnHighlight = useHighlight("return-detail-return");
  const sideHighlight = useHighlight("return-detail-side", {}, {
    style: {
      borderRadius: "var(--border-radius)",
      padding: "5px",
      margin: "-5px",
    }
  });

  if (!auth.isLoading && !permissions.orders) {
    return <Navigate to="./.." />;
  }

  if (isError) {
    if (error.status === 404) {
      return <NotFound type="return" />;
    }
    console.log(error);
    return <span>{t("Error...")}</span>;
  }

  const columns = [
    {
      title: t("Product"),
      dataIndex: "product",
      key: "name",
      render: product => (
        <Link to={`../products/${product.id}`} onClick={() => trackEvent("click", "return detail", "open product detail")}>
          <ImageTitle imageSrc={product.productImageMedium} title={product.title} subTitle={product.ean} subTitlePrefix={t("EAN")} />
        </Link>
      ),
    },
    {
      title: t("Handled"),
      dataIndex: "handled",
      key: "handled",
      width: 100,
      responsive: ["sm"],
      // eslint-disable-next-line i18next/no-literal-string
      render: handled => <HandledTag value={handled ? "all" : "none"} />
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      responsive: ["xl"],
    },
    {
      title: t("Unit price"),
      dataIndex: "unitPriceWithVat",
      key: "unitPrice",
      width: 110,
      render: text => toPrice(text),
      responsive: ["xl"],
    },
    {
      title: t("Total price"),
      dataIndex: "totalPriceWithVat",
      key: "unitPrice",
      width: 110,
      render: text => toPrice(text),
    },
    {
      title: t("Fulfilment method"),
      dataIndex: "fulfilmentMethod",
      key: "fulfilmentMethod",
      width: 160,
      responsive: ["md"],
    },
    {
      dataIndex: "product",
      key: "actions",
      width: 64,
      render: product => <Link to={`../products/${product.id}`} onClick={() => trackEvent("click", "return detail", "open product detail")}>
        <Button type="text" icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
      </Link>,
    },
  ];

  let flag;
  if (data?.billingCountry && hasFlag(data.billingCountry)) {
    const FlagComponent = flags[data.billingCountry];
    flag = <FlagComponent className={styles["flag"]} />;
  }

  const statistics = [
    { component: (<Statistic loading={isLoading} title={t("Return date")} value={dayjs(data?.returnDate).format("LL")} />) },
    { component: (<Statistic loading={isLoading} title={t("Handled")} formatter={() => { }} prefix={<HandledTag className={styles["large-tag"]} value={data?.handled} />} />) },
    { component: (<Statistic loading={isLoading} title={t("Total quantity")} value={data?.items?.reduce((c, item) => item?.quantity + c, 0)} />) },
    {
      component: (<Statistic loading={isLoading} title={t("Country")} suffix={flag} value={data?.billingCountry} />),
      cardProps: {
        className: styles["flag-card"]
      }
    },
  ];

  const main = [{
    cardProps: {
      bodyStyle: { padding: "0px" },
      ...returnHighlight?.props,
    },
    component: (
      <Table
        className={styles["return-table"]}
        rowKey="id"
        columns={columns}
        dataSource={data?.items}
        pagination={false}
        showSorterTooltip={false}
        loading={isLoading}
        expandable={{
          expandIcon: () => { },
          showExpandColumn: false,
          expandedRowKeys: data?.items.map(item => item.id),
          expandedRowRender: (record) => (
            <div className={styles["expandable-render"]}>
              <div>
                <div><span className={styles["bold"]}>{t("Return reason")}:</span> {`${record?.returnReason} ${record?.returnReason && record?.returnReasonDetailed && "/"} ${record?.returnReasonDetailed}`.trim()}</div>
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                  }}
                  title={record?.returnReasonComment}
                >
                  {record?.returnReasonComment}
                </Typography.Paragraph>
              </div>
              <div className={styles["hide-on-phone"]}>
                <div className={styles["bold"]}>{t("RMA ID")}</div>
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                  }}
                  title={record?.remoteId}
                >
                  {record?.remoteId}
                </Typography.Paragraph>
              </div>
              <div className={`${styles["right"]} ${styles["hide-on-phone"]}`}>
                <div className={styles["bold"]}>{t("Sender")}</div>
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                  }}
                  title={`${record?.customerFirstName} ${record?.customerLastName}`}
                >
                  {`${record?.customerFirstName} ${record?.customerLastName}`}
                </Typography.Paragraph>
              </div>
            </div>
          ),
        }} />
    ),
  }];

  const side = [
    {
      cardProps: {
        title: t("Order information"),
        extra: data?.items[0]?.order?.id && <Link to={`../orders/${data?.items[0]?.order?.id}`} onClick={() => trackEvent("click", "return detail", "open order detail")} >
          <Button icon={<ArrowRightOutlined />} />
        </Link>,
      },
      component: (
        <Datalist>
          <Datalist.Item labelBold colon label={t("Order date")}>{data?.items[0]?.order?.orderDate ? dayjs(data?.items[0]?.order?.orderDate).format("LL") : "-"}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Customer name")}>{data?.items[0]?.order?.billingDetails?.firstName ? `${data?.items[0]?.order?.billingDetails?.firstName} ${data?.items[0]?.order?.billingDetails?.lastName}` : "-"}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Street")}>{data?.items[0]?.order?.billingDetails?.street ? `${data?.items[0]?.order?.billingDetails?.street} ${data?.items[0]?.order?.billingDetails?.houseNumber}${data?.items[0]?.order?.billingDetails?.houseNumberExtension}`.trim() : "-"}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Postal code")}>{data?.items[0]?.order?.billingDetails?.postalCode ? data?.items[0]?.order?.billingDetails?.postalCode : "-"}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("City")}>{data?.items[0]?.order?.billingDetails?.city ? data?.items[0]?.order?.billingDetails?.city : "-"}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Country")}>{data?.items[0]?.order?.billingDetails?.country ? data?.items[0]?.order?.billingDetails?.country : "-"}</Datalist.Item>
        </Datalist>
      ),
    },
    {
      cardProps: {
        title: t("Transport information"),
      },
      component: (
        <Datalist>
          <Datalist.Item labelBold colon label={t("Transporter")}>{data?.items[0]?.transporter}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Track and trace")}>{data?.items[0]?.trackAndTrace}</Datalist.Item>
        </Datalist>
      ),
    },
    {
      cardProps: {
        title: t("IDs"),
      },
      component: (
        <Datalist>
          <Datalist.Item labelBold colon label={t("Sellevate ID")}>{data?.id}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Return ID")}>{data?.remoteId}</Datalist.Item>
        </Datalist>
      ),
    }
  ];

  return (
    <DetailLayout
      main={main}
      mainWidth={900}
      statistics={statistics}
      statisticsProps={statisticsHighlight?.props}
      side={side}
      sideProps={sideHighlight?.props}
    />
  );
}

export default ReturnDetail;
