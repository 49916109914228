import { NotificationCarousel } from "@sellevate-team/ui-components";
import { useTranslation } from "react-i18next";

export function ReturnDetailHelpDescription({ ...props }) {
  const { t } = useTranslation("help", { keyPrefix: "returnDetail" });
  return (
    <NotificationCarousel highlights={["return-detail-statistics", "return-detail-return", "return-detail-side"]} {...props}>
      <div>
        {t("At the top of the return page, the most important information of the return is displayed. Including the date and status.")}
      </div>
      <div>
        {t("The products that have been returned are listed here. The reason for return is also shown. From here you can go directly to the product page to view the product.")}
      </div>
      <div>
        {t("The customer information is displayed on the right. Here you can also navigate directly to the order. In addition, the transport information is displayed.")}
      </div>
    </NotificationCarousel>
  );
}
