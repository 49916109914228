import { useTranslation } from "react-i18next";

import styles from "./ConnectStoreHelp.module.scss";

export default function ConnectStoreHelp() {
  const { t } = useTranslation("settings");

  return (
    <>
      <ol className={styles["help-steps"]}>
        <li>{t("Choose the type of store you wish to connect and click on ‘$t(Connect)’.")}</li>
        <li>{t("Choose a connect option, fill in any required information and click on ‘$t(Connect)’.")}</li>
        <li>{t("If connecting through SSO: grant access to Sellevate.")}</li>
      </ol>
    </>
  );
}
