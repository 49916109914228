import dayjs from "dayjs";
import { useTranslation } from "react-i18next";


export function useDateRangeOptions() {
  const { t } = useTranslation();

  return [
    {
      label: t("This week"),
      startDate: dayjs().startOf("week"),
      endDate: dayjs().endOf("week"),
      key: "week"
    },
    {
      label: t("Last week"),
      startDate: dayjs().subtract(1, "week").startOf("week"),
      endDate: dayjs().subtract(1, "week").endOf("week"),
      key: "last-week"
    },
    {
      type: "divider",
    },
    {
      label: t("This month"),
      startDate: dayjs().startOf("month"),
      endDate: dayjs().endOf("month"),
      key: "month"
    },
    {
      label: t("Previous month"),
      startDate: dayjs().subtract(30, "day"),
      endDate: dayjs(),
      key: "previous-month"
    },
    {
      label: t("Last month"),
      startDate: dayjs().subtract(1, "month").startOf("month"),
      endDate: dayjs().subtract(1, "month").endOf("month"),
      key: "last-month"
    },
    {
      type: "divider",
    },
    {
      label: t("This year"),
      startDate: dayjs().startOf("year"),
      endDate: dayjs().endOf("year"),
      key: "year"
    },
    {
      label: t("Last year"),
      startDate: dayjs().subtract(1, "year").startOf("year"),
      endDate: dayjs().subtract(1, "year").endOf("year"),
      key: "last-year"
    },
    {
      label: t("Previous 90 days"),
      startDate: dayjs().subtract(90, "days"),
      endDate: dayjs(),
      key: "last-90-days",
      hideInMenu: true,
    },
    {
      label: t("Previous week"),
      startDate: dayjs().subtract(1, "week"),
      endDate: dayjs(),
      key: "previous-week",
      hideInMenu: true,
    },
  ];
}
