import { CopyOutlined } from "@ant-design/icons";
import { Table, Button, Datalist, ImageTitle, message, Statistic, useHighlight } from "@sellevate-team/ui-components";
import { ChevronDownIcon } from "@sellevate-team/ui-components/dist/icons";
import { DetailLayout } from "@sellevate-team/ui-components/dist/layouts";
import { hasFlag } from "country-flag-icons";
import flags from "country-flag-icons/react/3x2";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useParams } from "react-router-dom";

import { useAuth } from "../auth/AuthContext";
import { useGtag, useOrderDetail } from "../client/hooks";
import { copyToClipboard } from "../utils/copy";
import { useDynamicPathLabel } from "../utils/router";
import { useStoreInfo } from "../utils/StoreInfoContext";
import { toPrice } from "../utils/valueProcessing";

import NotFound from "./404.jsx";
import { StatusTag } from "./OrderList.jsx";

import styles from "./OrderDetail.module.scss";

function OrderDetail() {
  const auth = useAuth();
  const { permissions } = auth;
  const { t } = useTranslation();
  const { storeIds } = useStoreInfo();
  const { orderId } = useParams();
  const { trackEvent } = useGtag();
  const { isLoading, isError, data, error } = useOrderDetail(storeIds, orderId);
  useDynamicPathLabel(data && `${data?.id} ${data?.billingDetails?.firstName && "-"} ${data?.billingDetails?.firstName} ${data?.billingDetails?.lastName}`.trim());

  const statisticsHighlight = useHighlight("order-detail-statistics", {}, {
    style: {
      borderRadius: "var(--border-radius)",
      padding: "5px",
      margin: "-5px",
    }
  });

  const orderHighlight = useHighlight("order-detail-order");
  const sideHighlight = useHighlight("order-detail-side", {}, {
    style: {
      borderRadius: "var(--border-radius)",
      padding: "5px",
      margin: "-5px",
    }
  });

  if (!auth.isLoading && !permissions.orders) {
    return <Navigate to="./.." />;
  }

  if (isError) {
    if (error.status === 404) {
      return <NotFound type="order" />;
    }
    console.log(error);
    return <span>{t("Error...")}</span>;
  }

  const handleCopyBillingEmail = () => {
    copyToClipboard(data?.billingDetails?.email).then(() => {
      message.success(t("Successfully copied email to clipboard"));
    }).catch(() => {
      message.error(t("Failed to copy email to clipboard"));
    });
  };

  const columns = [
    {
      title: t("Product"),
      dataIndex: "product",
      key: "name",
      render: product => (product ? (
        <Link to={`../products/${product.id}`} onClick={() => trackEvent("click", "order detail", "open product detail")}>
          <ImageTitle imageSrc={product.productImageMedium} title={product.title} subTitle={product.ean} subTitlePrefix={t("EAN")} />
        </Link>
      ) : (
        <ImageTitle imageSrc={""} title={t("Unknown product")} subTitle="-" subTitlePrefix={t("EAN")} />
      )
      ),
    },
    {
      title: t("Quantity"),
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
      responsive: ["xl"],
    },
    {
      title: t("Unit price"),
      dataIndex: "unitPriceWithVat",
      key: "unitPrice",
      width: 110,
      render: text => toPrice(text),
      responsive: ["xl"],
    },
    {
      title: t("Total price"),
      dataIndex: "totalPriceWithVat",
      key: "unitPrice",
      width: 110,
      render: text => toPrice(text),
    },
    {
      title: t("Commission"),
      dataIndex: "totalCommission",
      key: "commission",
      width: 120,
      render: text => toPrice(text),
      responsive: ["sm"],
    },
    {
      title: t("Fulfilment method"),
      dataIndex: "fulfilmentMethod",
      key: "fulfilmentMethod",
      width: 160,
      responsive: ["md"],
    },
    {
      dataIndex: "product",
      key: "actions",
      width: 64,
      render: product => (product ? (
        <Link to={`../products/${product.id}`} onClick={() => trackEvent("click", "order detail", "open product detail")}>
          <Button type="text" icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
        </Link>
      ) : (
        <Button type="text" disabled icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
      )
      ),
    },
  ];

  let flag;
  if (data?.billingDetails?.country && hasFlag(data.billingDetails.country)) {
    const FlagComponent = flags[data.billingDetails.country];
    flag = <FlagComponent className={styles["flag"]} />;
  }

  const statistics = [
    { component: (<Statistic loading={isLoading} title={t("Order date")} value={dayjs(data?.orderDate).format("LL")} />) },
    { component: (<Statistic loading={isLoading} title={t("Total price")} value={toPrice(data?.totalPriceWithVat)} />) },
    { component: (<Statistic loading={isLoading} title={t("Total commission")} value={toPrice(data?.items?.reduce((c, item) => item?.totalCommission + c, 0))} />) },
    { component: (<Statistic loading={isLoading} title={t("Total quantity")} value={data?.items?.reduce((c, item) => item?.quantity + c, 0)} />) },
    { component: (<Statistic loading={isLoading} title={t("Status")} formatter={() => { }} prefix={<StatusTag className={styles["large-tag"]} status={data?.status} />} />) },
    {
      component: (<Statistic loading={isLoading} title={t("Country")} suffix={flag} value={data?.billingDetails?.country || "-"} />),
      cardProps: {
        className: styles["flag-card"]
      }
    },
  ];

  const main = [{
    cardProps: {
      bodyStyle: { padding: "0px" },
      ...orderHighlight?.props,
    },
    component: (
      <Table
        className={styles["order-table"]}
        rowKey="id"
        columns={columns}
        dataSource={data?.items}
        pagination={false}
        loading={isLoading}
        showSorterTooltip={false} />
    ),
  }];

  const side = [
    {
      cardProps: {
        title: t("Customer information"),
        extra: <Button icon={<CopyOutlined />} disabled={!data?.billingDetails?.email} onClick={() => { handleCopyBillingEmail(); trackEvent("click", "order detail", "copy email"); }} title={t("Copy email")} />,
      },
      component: (
        <Datalist>
          <Datalist.Item labelBold colon label={t("Name")}>{`${data?.billingDetails?.firstName} ${data?.billingDetails?.lastName}`.trim()}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Street")}>{`${data?.billingDetails?.street} ${data?.billingDetails?.houseNumber} ${data?.billingDetails?.houseNumberExtension}`.trim()}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Postal code")}>{data?.billingDetails?.postalCode}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("City")}>{data?.billingDetails?.city}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Country")}>{data?.billingDetails?.country}</Datalist.Item>
        </Datalist>
      ),
    },
    {
      cardProps: {
        title: t("IDs"),
      },
      component: (
        <Datalist>
          <Datalist.Item labelBold colon label={t("Sellevate ID")}>{data?.id}</Datalist.Item>
          <Datalist.Item labelBold colon label={t("Order ID")}>{data?.remoteId}</Datalist.Item>
        </Datalist>
      ),
    }
  ];

  return (
    <DetailLayout
      main={main}
      mainWidth={900}
      statistics={statistics}
      statisticsProps={statisticsHighlight?.props}
      side={side}
      sideProps={sideHighlight?.props}
    />
  );
}

export default OrderDetail;
