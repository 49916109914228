/* eslint-disable i18next/no-literal-string */
export function toPrice(value, hideDecimals = false) {
  if (value === "" || value === null || value === undefined) {
    return "€-";
  }
  if (isNaN(value)) {
    return "€-";
  }
  try {
    let negative = false;
    let price = parseFloat(value).toFixed(2).replace(".", ",");
    if (price.charAt(0) == "-") {
      negative = true;
    }
    let a = /(\d+?)((?:\d{3})*)(,\d{2})/.exec(price);
    let f = (s) => s.match(/.{3}/g);
    let m = f(a[2]);
    // a[3] captures the decimals, hide them when desired.
    if (hideDecimals) a[3] = "";
    if (m == null) {
      return negative ? "-€" + a[1] + a[3] : "€" + a[1] + a[3];
    }
    return negative ? "-€" + ([a[1]].concat(f(a[2]))).join(".") + a[3] : "€" + ([a[1]].concat(f(a[2]))).join(".") + a[3];
  } catch (e) {
    return "€-";
  }
}

export function toShortPrice(value) {
  if (value === "" || value === null || value === undefined) {
    return "€-";
  }
  if (isNaN(value)) {
    return "€-";
  }
  try {
    if (value >= 1e6) {
      return parseFloat(value / 1e6).toFixed(1).replace(".", ",") + "M";
    } else if (value >= 1e4) {
      return parseFloat(value / 1e3).toFixed(0).replace(".", ",") + "K";
    }
    return toPrice(value);
  } catch (e) {
    return "€-";
  }
}

export function toPercentage(value, decimals = 2) {
  if (value === "" || value === null || value === undefined) {
    return "-%";
  }
  if (isNaN(value)) {
    return "-%";
  }
  try {
    return parseFloat(value).toFixed(decimals).replace(".", ",") + "%";
  } catch (e) {
    return "-%";
  }
}

export function toRating(value) {
  /**
   * expects a number between 0 and 50
   */
  if (value === "" || value === null || value === undefined) {
    return "- / 5.0";
  }
  if (isNaN(value)) {
    return "- / 5.0";
  }
  return `${value / 10} / 5.0`;
}
