import { Button, Table, ImageTitle, Responsive, Skeleton, useHighlight } from "@sellevate-team/ui-components";
import { ChevronDownIcon } from "@sellevate-team/ui-components/dist/icons";
import { ListLayout } from "@sellevate-team/ui-components/dist/layouts";
import { ShuffleBlur } from "@sellevate-team/ui-components/dist/permissions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import { useAuth } from "../auth/AuthContext";
import { useGtag, useProductListInfinite } from "../client/hooks";
import NoStoreAlert from "../components/NoStoreAlert.jsx";
import { useDateRange } from "../utils/dateRange";
import { usePageSearchParams, useSearchParamsState } from "../utils/searchParamsState";
import { useSearchQuery } from "../utils/searchQuery";
import { useStoreInfo } from "../utils/StoreInfoContext";
import { toPrice } from "../utils/valueProcessing";


function ProductList() {
  const { permissions } = useAuth();
  const { t } = useTranslation();
  const { storeIds, selectedStores, selectedStoreIds } = useStoreInfo();
  const { fromStr, toStr } = useDateRange();
  const { debouncedSearchQuery } = useSearchQuery();
  const { trackEvent } = useGtag();
  const { currentPageParams } = useSearchParamsState();
  const DEFAULT_ORDERING = "-revenue";
  const [ordering, setOrdering] = useState(currentPageParams["order"] || DEFAULT_ORDERING);
  const { data, error, fetchNextPage, hasNextPage, isLoading, isError, isPreviousData, isFetching } = useProductListInfinite(storeIds, fromStr, toStr, debouncedSearchQuery, ordering);
  const isLoadingNewData = (isPreviousData && isFetching) || isLoading;

  const orderingField = ordering.replace(/^-/, "");
  // eslint-disable-next-line i18next/no-literal-string
  const orderingDir = ordering.at(0) === "-" ? "descend" : "ascend";

  usePageSearchParams({
    "order": ordering === DEFAULT_ORDERING ? undefined : ordering,
  });

  const listHighlight = useHighlight("product-list-list", { before: true });

  if (isError) {
    console.log(error);
    return <span>{t("Error...")}</span>;
  }

  const columns = [
    {
      title: t("Product"),
      dataIndex: "title",
      key: "title",
      sorter: true,
      render: (title, product) => (
        <Link to={`../products/${product.id}`} onClick={() => trackEvent("click", "product list", "open product detail")}>
          <ImageTitle imageSrc={product.productImageMedium} title={title} subTitle={product.ean} subTitlePrefix={t("EAN")} />
        </Link>
      ),
    },
    {
      title: t("Stock"),
      dataIndex: "stock",
      key: "stock",
      width: 100,
      sorter: true,
      responsive: ["sm"],
    },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
      sorter: true,
      width: 100,
      responsive: ["md"],
      render: text => toPrice(text),
    },
    {
      title: t("Sold"),
      dataIndex: "sold",
      key: "sold",
      sorter: permissions.orders,
      sortDirections: ["descend"],
      width: 100,
      responsive: ["lg"],
      render: text => <ShuffleBlur limited={!permissions.orders}>{text === null ? "-" : text}</ShuffleBlur>,
    },
    {
      title: t("Revenue"),
      dataIndex: "revenue",
      key: "revenue",
      sorter: permissions.orders,
      sortDirections: ["descend"],
      width: 100,
      render: text => <ShuffleBlur limited={!permissions.orders}>{toPrice(text)}</ShuffleBlur>,
    },
    {
      title: t("Store"),
      dataIndex: "store",
      key: "store",
      width: 160,
      responsive: ["xl"],
      render: id => selectedStores[id]?.name,
      hide: selectedStoreIds.length < 2,
    },
    {
      dataIndex: "id",
      key: "actions",
      width: 64,
      render: id => <Link to={`../products/${id}`} onClick={() => trackEvent("click", "product list", "open product detail")}>
        <Button type="text" icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
      </Link>,
    },
  ].filter(c => !c.hide)
    .map(column => {
      if (column.dataIndex === orderingField) {
        column.defaultSortOrder = orderingDir;
      }
      return column;
    });

  const handleTableChange = (newPagination, filters, sorter) => {
    trackEvent("click", "product list", "sort");
    if (sorter.field && sorter.order) {
      setOrdering((sorter.order === "ascend" ? "" : "-") + sorter.field);
    } else {
      setOrdering("");
    }
  };

  return (
    <Responsive.Space direction="vertical">
      <NoStoreAlert />
      <ListLayout sticky {...listHighlight?.props}>
        <InfiniteScroll
          style={{ overflow: "initial" }}
          dataLength={data?.pages.flatMap((group, i) => group.results).length || 0}
          next={() => fetchNextPage()}
          hasMore={isLoadingNewData ? true : hasNextPage}
          loader={<Skeleton title paragraph={false} active />}
        >
          <Table
            sticky
            rowKey="id"
            columns={columns}
            dataSource={data?.pages.flatMap((group, i) => group.results)}
            pagination={false}
            loading={isLoadingNewData}
            onChange={handleTableChange}
            showSorterTooltip={false} />
        </InfiniteScroll>
      </ListLayout>
    </Responsive.Space>
  );
}

export default ProductList;
