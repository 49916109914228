import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip, useNavigation } from "@sellevate-team/ui-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";


const InfoButton = React.forwardRef(
  function InfoButton({ helpPage, onClick, revertColors, children, ...props }, ref) {
    const { t } = useTranslation();
    const { handleClickHelp } = useNavigation();
    return (
      <Tooltip title={t("Show help")} mouseEnterDelay={0.3}>
        <Button
          type={revertColors ? "primary" : "text"}
          shape={revertColors ? "circle" : undefined}
          size={revertColors ? "small" : undefined}
          icon={<InfoCircleOutlined style={{ color: revertColors ? "white" : "var(--primary-color)" }} />}
          onClick={(e) => {
            if (onClick?.(e) === false) {
              return;
            }
            handleClickHelp({ defaultPage: helpPage ?? undefined });
          }}
          ref={ref}
          {...props}>
          {children}
        </Button>
      </Tooltip>
    );
  }
);

InfoButton.propTypes = {
  helpPage: PropTypes.number,
  onClick: PropTypes.func,
  revertColors: PropTypes.bool,
  children: PropTypes.node,
};

export default InfoButton;
