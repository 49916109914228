import { Card } from "@sellevate-team/ui-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, Routes, Route } from "react-router-dom";

import { useDynamicPathLabel } from "../../utils/router.js";

import ConnectedStores from "./Stores/Connected.jsx";
import StoresDiscover from "./Stores/Discover.jsx";

import styles from "./Settings.module.scss";

function SettingsStores() {
  const { t } = useTranslation("settings");
  const { tab } = useParams();
  const navigate = useNavigate();

  const labels = useMemo(() => ({
    new: t("New stores"),
    connected: t("Connected stores"),
  }), [t]);

  useDynamicPathLabel(t("Stores"), "..");
  useDynamicPathLabel(labels[tab]);

  const tabList = [
    {
      key: "new",
      tab: t("New stores"),
    },
    {
      key: "connected",
      tab: t("Connected stores"),
    },
  ];

  const tabContent = {
    new: <Routes>
      <Route index element={<StoresDiscover />} />
      <Route path=":slug" element={<StoresDiscover />} />
    </Routes>,
    connected: <Routes>
      <Route index element={<ConnectedStores />} />
      <Route path=":storeId" element={<ConnectedStores />} />
      <Route path=":storeId/*" element={<ConnectedStores />} />
    </Routes>
  };

  return (
    <Card
      className={styles["stores-tabs"]}
      tabList={tabList}
      activeTabKey={tab}
      onTabChange={key => navigate(`../${key}`)}
    >
      {tabContent[tab]}
    </Card>
  );
}


export default SettingsStores;
