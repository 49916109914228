import { WarningOutlined, CloseOutlined, CheckOutlined, RollbackOutlined, ShoppingCartOutlined, DeploymentUnitOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Collapse, Steps, Input, message, Avatar, Modal, Form, Space, Spin, Alert, Tag, Divider } from "@sellevate-team/ui-components";
import { SettingsIcon, StockIcon } from "@sellevate-team/ui-components/dist/icons";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useParams, useNavigate } from "react-router-dom";

import { useAuth } from "../../../auth/AuthContext";
import {
  useConnectStores,
  useConnectStoresDetail,
  useConnectStoresDetailMutation,
  useConnectStoresRemoveMutation,
  useConnectStoresRestoreMutation,
} from "../../../client/hooks";
import { useDynamicPathLabel, useRelativeMatch } from "../../../utils/router";
import { usePageSearchParams, useSearchParamsState } from "../../../utils/searchParamsState";

import { StoreConnectForm } from "./Discover.jsx";

import styles from "./Stores.module.scss";


function UpdateStoreNameForm({ storeId, name, onSuccess }) {
  const { t } = useTranslation("settings");
  const [form] = Form.useForm();
  const mutation = useConnectStoresDetailMutation(storeId, {
    onError: (error, variables, context) => {
      message.error(t("Something went wrong, please try again."));
    },
    onSuccess: (data, variables, context) => {
      message.success(t("Store name changed successfully"));
      onSuccess(data);
    },
  });

  const onFinish = (values) => {
    const data = {
      name: values.name,
    };

    mutation.mutate(data);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Form
      form={form}
      name={storeId}
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ name: name }}
      style={{ gap: "12px" }}
      onFinish={onFinish}
      autoComplete="off"
      layout="inline"
    >
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <Form.Item name="name">
        <Input placeholder={t("Store name")} />
      </Form.Item>

      <Form.Item shouldUpdate
        style={{ justifyContent: "center" }}>
        {() => (
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isLoading}
              disabled={
                !form.isFieldsTouched(true) ||
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0
              }>
              {t("Save")}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              {t("Reset")}
            </Button>
          </Space>
        )}
      </Form.Item>
    </Form>
  );
}

UpdateStoreNameForm.propTypes = {
  storeId: PropTypes.string,
  name: PropTypes.string,
  onSuccess: PropTypes.func,
};

function RenewCredentials({ storeId, storeName, onSuccess }) {
  const { t } = useTranslation("settings");
  const { isLoading, isError, data } = useConnectStoresDetail(storeId);

  if (isError) {
    return <span>{t("Error...")}</span>;
  }

  const currentOption = data?.options.find(option => option.name === data?.credentials?.optionName);
  const advancedOptions = data?.options.filter(option => option.name !== currentOption.name);

  return (
    <>
      <Spin spinning={isLoading}>
        <p>
          {t("Renew the authentication for store ‘{{ store }}’ by filling in one of the following options.", { store: storeName })}
        </p>
        <Alert message={t("Make sure that you use the correct credentials for this store")} type="warning" showIcon />
        {currentOption && (
          <StoreConnectForm
            key={currentOption.id}
            storeSlug={data?.slug}
            updateAction={true}
            subText={<Alert message={t("Currently used option")} type="info" showIcon style={{ marginBottom: "16px" }} />}
            option={currentOption}
            onSuccess={onSuccess} />
        )}
        {advancedOptions && (
          <Collapse>
            <Collapse.Panel header={t("Advanced options")}>
              {advancedOptions.map(option => (
                <StoreConnectForm
                  key={option.id}
                  storeSlug={data?.slug}
                  updateAction={true}
                  option={option}
                  onSuccess={onSuccess} />
              ))}
            </Collapse.Panel>
          </Collapse>
        )}
      </Spin>
    </>
  );
}

RenewCredentials.propTypes = {
  storeId: PropTypes.string,
  storeName: PropTypes.string,
  onSuccess: PropTypes.func,
};

function ConnectedStores() {
  const { t } = useTranslation("settings");
  const queryClient = useQueryClient();
  const { isLoading, isError, data } = useConnectStores({
    refetchInterval: 1000 * 60,
  });
  const { storeId } = useParams();
  const renewMatch = useRelativeMatch("./renew");
  const { currentPageParams } = useSearchParamsState();
  let initialStoreState = undefined;
  if (currentPageParams["success"] === "true") {
    // eslint-disable-next-line i18next/no-literal-string
    initialStoreState = "success";
  } else if (currentPageParams["error"]) {
    initialStoreState = currentPageParams["error"];
  }
  const [connectStoreState, setConnectStoreState] = useState(initialStoreState);
  const navigate = useNavigate();
  const { user } = useAuth();
  const removeMutation = useConnectStoresRemoveMutation(storeId, {
    onError: (error, variables, context) => {
      message.error(t("Something went wrong, please try again."));
    },
    onSuccess: (data, variables, context) => {
      message.success(t("Store removed successfully"));
      queryClient.invalidateQueries(["connect", "stores"], { exact: true });
    },
  });
  const restoreMutation = useConnectStoresRestoreMutation(storeId, {
    onError: (error, variables, context) => {
      message.error(t("Something went wrong, please try again."));
    },
    onSuccess: (data, variables, context) => {
      message.success(t("Store restored successfully"));
      queryClient.invalidateQueries(["connect", "stores"], { exact: true });
      queryClient.invalidateQueries(["connect", "stores", storeId], { exact: true });
    },
  });

  const selectedItem = data?.find(item => item.id === storeId);

  // With an item selected, add the name of the item as the breadcrumb path label
  useDynamicPathLabel(selectedItem?.name);

  // Close the popup/modal when no item is found in the data (and the data is loaded)
  useEffect(() => {
    if (!isLoading && !selectedItem && storeId) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, selectedItem, storeId]);

  usePageSearchParams({
    // eslint-disable-next-line i18next/no-literal-string
    "success": connectStoreState === "success" ? "true" : undefined,
    "error": connectStoreState && connectStoreState !== "success" ? connectStoreState : undefined,
  });

  useEffect(() => {
    const error = connectStoreState && connectStoreState !== "success" ? connectStoreState : null;
    const success = connectStoreState === "success";
    if (error || success) {
      switch (error) {
        case "store_already_exists":
          message.error(t("This store is already connected to your account."));
          break;
        case "credentials_from_different_store":
          message.error(t("The new credentials do not correspond to the existing store, try another store."));
          break;
        case "invalid_setup":
          message.error(t("Something went wrong with setting up the store, try connecting a new store."));
          break;
        case "invalid_state":
          message.error(t("Something went wrong during the authentication process, try again."));
          break;
        case "invalid_code":
          message.error(t("Something went wrong during the authentication process, try again."));
          break;
        case "access_denied":
          message.error(t("Authentication failed, try again"));
          break;
        case "missing_data":
          message.error(t("Something went wrong, try again"));
          break;
        default:
          if (error) {
            message.error(error);
          }
          break;
      }

      if (success) {
        message.success(t("Store added successfully"));
      }

      setConnectStoreState(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectStoreState]);

  if (isError) {
    return <span>{t("Error...")}</span>;
  }

  const showModal = (item) => {
    navigate(`./${item.id}`);
  };

  const handleClose = () => {
    navigate("..");
  };

  const handleUpdateNameSuccess = (data) => {
    // Invalidate the query
    queryClient.invalidateQueries(["stores"], { exact: true });
    // queryClient.invalidateQueries(["connect", "stores"], { exact: true });
    // Or, update using the local data
    queryClient.cancelQueries(["connect", "stores"], { exact: true });
    queryClient.setQueryData(["connect", "stores"], old => old.map(item => {
      if (item.id === data.id) {
        item.name = data.name;
      }
      return item;
    }));
  };

  const handleOpenRenewCredentials = () => {
    // eslint-disable-next-line i18next/no-literal-string
    navigate("./renew");
  };

  const handleRenewSuccess = (data) => {
    if (data.renewAuthUrl) {
      // Redirect to the renew auth url
      window.location.href = data.renewAuthUrl;
    } else {
      queryClient.invalidateQueries(["stores"], { exact: true });
      queryClient.invalidateQueries(["connect", "stores"], { exact: true });
      queryClient.invalidateQueries(["connect", "stores", storeId], { exact: true });
      navigate(".");
    }
  };

  const handleDeleteStore = (storeId) => {
    return new Promise((resolve, reject) => {
      removeMutation.mutate(null, {
        onSuccess: () => {
          queryClient.invalidateQueries(["stores"], { exact: true });
        },
        onSettled: (data, error, variables, context) => {
          resolve();
        },
      });
    });
  };

  const handleRestoreStore = (storeId) => {
    return new Promise((resolve, reject) => {
      restoreMutation.mutate(null, {
        onSuccess: () => {
          queryClient.invalidateQueries(["stores"], { exact: true });
        },
        onSettled: (data, error, variables, context) => {
          resolve();
        },
      });
    });
  };

  function getStatusTag(status) {
    const lookup = {
      draft: {
        text: t("Draft"),
        color: "var(--primary-color)",
      },
      active: {
        text: t("Active"),
        color: "var(--accent-color)",
      },
      onboarding: {
        text: t("Onboarding"),
        color: "var(--primary-color)",
      },
      paused: {
        text: t("Paused"),
        color: "var(--ant-warning-color)",
      },
      failed: {
        text: t("Failed"),
        color: "var(--ant-error-color)",
      },
      removed: {
        text: t("Removed"),
        color: "var(--ant-error-color",
      },
    };
    let data = status in lookup ? lookup[status] : { text: "-", color: "default" };
    return <Tag color={data.color}>{data.text}</Tag>;
  }

  const columns = [
    {
      title: t("Store"),
      dataIndex: "name",
      key: "title",
      render: (n, store) => (
        <a onClick={() => showModal(store)}>
          <span className={styles["store-column"]} >
            <Avatar src={store.icon} />
            <span className={styles["row"]}>{n}</span>
          </span>
        </a>
      )
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      width: "20%",
      responsive: ["sm"],
      // eslint-disable-next-line i18next/no-literal-string
      render: (status, s) => getStatusTag(status === "active" && s?.onboarding ? "onboarding" : status),
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
      width: "20%",
      responsive: ["sm"]
    },
    {
      title: t("Credentials"),
      dataIndex: "credentials",
      key: "credentials",
      width: "20%",
      render: c => c.valid ? (
        dayjs().isAfter(dayjs(c.expirationNoticeAt)) ? (
          <WarningOutlined style={{ color: "var(--ant-warning-color)" }} title={t("Credentials will expire in {{count}} days", { count: dayjs(c.expiresAt).diff(dayjs(), "day") })} />
        ) : (
          <CheckOutlined style={{ color: "var(--ant-success-color)" }} title={t("Credentials are valid")} />
        )
      ) : <WarningOutlined style={{ color: "var(--ant-error-color)" }} title={t("Credentials are expired")} />
    },
    {
      dataIndex: "id",
      key: "actions",
      width: 64,
      render: (id, item) => <Button icon={<SettingsIcon />} onClick={() => showModal(item)} />,
    },
  ];

  const canRestore = user?.subscription?.connectStorePermission && selectedItem?.canRestore;

  const onboardingItems = [
    {
      title: t("Products onboarding"),
      icon: <StockIcon />,
      onboarded: selectedItem?.productsOnboarded,
      loading: false,
    },
    {
      title: t("Orders onboarding"),
      icon: <ShoppingCartOutlined />,
      onboarded: selectedItem?.ordersOnboarded,
      loading: false,
    },
    {
      title: t("Returns onboarding"),
      icon: <RollbackOutlined />,
      onboarded: selectedItem?.returnsOnboarded,
      loading: false,
    },
    {
      title: t("Webhooks onboarding"),
      icon: <DeploymentUnitOutlined />,
      onboarded: selectedItem?.webhooksOnboarded,
      loading: false,
    }
  ];

  const loadingItem = onboardingItems.find(item => !item.onboarded);
  if (loadingItem) loadingItem.loading = true;

  const stores = data?.filter(d => d.status !== "removed");
  const removedStores = data?.filter(d => d.status === "removed");

  return (
    <>
      <Table
        loading={isLoading}
        rowKey="id"
        columns={columns}
        dataSource={stores?.length > 0 ? stores : removedStores}
        pagination={false}
        showSorterTooltip={false}
        className={styles["connected-table"]}
        footer={() => stores?.length > 0 && removedStores?.length > 0 && (
          <Collapse>
            <Collapse.Panel header={t("Removed stores")}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={removedStores}
                pagination={false}
                showSorterTooltip={false}
                showHeader={false}
              />
            </Collapse.Panel>
          </Collapse>
        )}
      />
      <Modal
        title={<Space>{selectedItem?.type}{getStatusTag(selectedItem?.status)}</Space>}
        visible={storeId !== undefined && !renewMatch}
        onCancel={handleClose}
        footer={null}
        destroyOnClose>
        <Spin spinning={isLoading}>
          {selectedItem?.status === "removed" ? (
            <>
              <Space direction="vertical">
                {selectedItem?.name}
              </Space>
              <Divider />
              <Space direction="vertical">
                <Popconfirm
                  title={t("Are you sure?")}
                  okText={t("OK")}
                  onConfirm={() => handleRestoreStore(selectedItem?.id)}
                  cancelText={t("Cancel")}>
                  <Button disabled={!canRestore}>{t("Restore removed store")}</Button>
                </Popconfirm>
                {selectedItem?.deleteAt && (
                  <span>
                    {t("Store will be permanently deleted on {{ deleteAt, datetime }}", {
                      deleteAt: new Date(selectedItem.deleteAt)
                    })}
                  </span>
                )}
                {!canRestore && (
                  <span>
                    {t("Start a subscription in order to restore removed stores.")}
                  </span>
                )}
              </Space>
            </>
          ) : (
            <>
              {selectedItem?.status === "active" && (selectedItem?.onboarding ? (
                <>
                  <p>
                    {t("We are busy setting up your store. You can click this popup away and come back later to view your connected store or view the partially retrieved data by going to the dashboard.")}
                  </p>
                  {/* eslint-disable i18next/no-literal-string */}
                  <Steps direction="vertical" size="small" style={{ marginBottom: "-24px" }}>
                    {onboardingItems.map((item, i) => (
                      <Steps.Step key={i} className={!item.onboarded ? `${styles["onboarding-step"]} ${styles["disabled"]}` : styles["onboarding-step"]} title={item.title} icon={item.loading ? <><LoadingOutlined />{item.icon}</> : item.icon} status={item.onboarded ? "finish" : "process"} />
                    ))}
                  </Steps>
                  {/* eslint-enable i18next/no-literal-string */}
                  <Divider />
                </>
              ) : (
                <>
                  <span><CheckOutlined style={{ color: "var(--ant-success-color)", marginRight: "10px" }} /> {t("Onboarding done")}</span>
                  <Divider />
                </>
              ))}
              {selectedItem?.status === "failed" && (
                <>
                  <span><CloseOutlined style={{ color: "var(--ant-error-color)", marginRight: "10px" }} /> {t("Updating store failed, try renewing the authentication. Please contact us if the problem persists.")}</span>
                  <Divider />
                </>
              )}
              {(selectedItem?.status === "paused" && !selectedItem?.credentials?.valid) && (
                <>
                  <span><WarningOutlined style={{ color: "var(--ant-error-color)", marginRight: "10px" }} /> {t("This store is paused because its credentials have expired, try renewing the authentication.")}</span>
                  <Divider />
                </>
              )}
              <Space direction="vertical">
                <UpdateStoreNameForm
                  storeId={selectedItem?.id}
                  name={selectedItem?.name}
                  onSuccess={handleUpdateNameSuccess} />
                {selectedItem?.connectedAt && (
                  t("Store connected on {{ connectedAt, datetime }}", {
                    connectedAt: new Date(selectedItem.connectedAt)
                  })
                )}
              </Space>
              <Divider />
              <Space direction="vertical">
                {selectedItem?.continueAuthUrl && (
                  <Button target="_self" href={selectedItem?.continueAuthUrl}>{t("Continue connecting")}</Button>
                )}
                <Button
                  type={(selectedItem?.status === "paused" && !selectedItem?.credentials?.valid) ? "primary" : undefined}
                  onClick={handleOpenRenewCredentials}>
                  {t("Renew authentication")}
                </Button>
                {selectedItem?.credentials?.expiresAt && (
                  t("Authentication expires on {{ expiresAt, datetime }}", {
                    expiresAt: new Date(selectedItem.credentials.expiresAt)
                  })
                )}
                <Popconfirm
                  title={t("Are you sure?")}
                  okText={t("OK")}
                  okButtonProps={{ danger: true }}
                  onConfirm={() => handleDeleteStore(selectedItem?.id)}
                  cancelText={t("Cancel")}>
                  <Button danger>{t("Delete")}</Button>
                </Popconfirm>
              </Space>
            </>
          )}
        </Spin>
      </Modal>
      <Modal
        title={<Space>{selectedItem?.type}{getStatusTag(selectedItem?.status)}</Space>}
        visible={!!renewMatch}
        onCancel={() => navigate(".")}
        footer={null}
        destroyOnClose>
        <RenewCredentials
          storeId={storeId}
          storeName={selectedItem?.name}
          onSuccess={handleRenewSuccess} />
      </Modal>
    </>
  );
}


export default ConnectedStores;
