import { Button, Responsive, Skeleton, Table, Tag, useHighlight } from "@sellevate-team/ui-components";
import { ChevronDownIcon } from "@sellevate-team/ui-components/dist/icons/index.js";
import { ListLayout } from "@sellevate-team/ui-components/dist/layouts/index.js";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import { useAuth } from "../auth/AuthContext.js";
import { useGtag, useReturnListInfinite } from "../client/hooks.js";
import NoStoreAlert from "../components/NoStoreAlert.jsx";
import NoPermissionEmpty from "../components/permissions/NoPermissionEmpty.jsx";
import { useDateRange } from "../utils/dateRange.js";
import { useSearchQuery } from "../utils/searchQuery.js";
import { useStoreInfo } from "../utils/StoreInfoContext.js";
import { toPrice } from "../utils/valueProcessing.js";

export function HandledTag({ className, style, value }) {
  const { t } = useTranslation();

  const lookup = {
    all: {
      text: t("Yes"),
      color: "var(--accent-color)",
    },
    partial: {
      text: t("Partially"),
      color: "var(--ant-warning-color)",
    },
    none: {
      text: t("No"),
      color: "var(--primary-color)",
    },
  };
  let data = value in lookup ? lookup[value] : { text: "-", color: "default" };
  let classList = [];
  if (className) classList.push(className);
  return <Tag style={style} className={classList.join(" ")} color={data.color}>{data.text}</Tag>;
}

HandledTag.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
};


function ReturnList() {
  const { t } = useTranslation();
  const { storeIds, selectedStores, selectedStoreIds } = useStoreInfo();
  const { fromStr, toStr } = useDateRange();
  const { debouncedSearchQuery } = useSearchQuery();
  const { permissions } = useAuth();
  const { trackEvent } = useGtag();
  const { data, error, fetchNextPage, hasNextPage, isLoading, isError } = useReturnListInfinite(storeIds, fromStr, toStr, debouncedSearchQuery);

  const listHighlight = useHighlight("return-list-list", { before: true });

  const columns = [
    {
      title: t("Customer"),
      dataIndex: "customerName",
      key: "customerName",
      render: (_, { billingFirstName, billingLastName, id }) => <Link to={`./${id}`} onClick={() => trackEvent("click", "return list", "open return detail")}>
        {billingFirstName ? `${billingFirstName} ${billingLastName}` : "-"}
      </Link>,
    },
    {
      title: t("Handled"),
      dataIndex: "handled",
      key: "handled",
      render: handled => <HandledTag value={handled} />
    },
    {
      title: t("Date"),
      dataIndex: "returnDate",
      key: "date",
      responsive: ["sm"],
      render: text => dayjs(text).format("LLL"),
    },
    {
      title: t("Total price"),
      dataIndex: "totalPriceWithVat",
      key: "totalPrice",
      responsive: ["sm"],
      render: text => toPrice(text),
    },
    {
      title: t("Items"),
      dataIndex: "items",
      key: "nrItems",
      responsive: ["md"],
      render: items => items.length
    },
    {
      title: t("Country"),
      dataIndex: "billingCountry",
      key: "country",
      responsive: ["md"],
      render: text => text ? text : "-"
    },
    {
      title: t("Store"),
      dataIndex: "store",
      key: "store",
      width: 160,
      responsive: ["xl"],
      render: id => selectedStores[id]?.name,
      hide: selectedStoreIds.length < 2,
    },
    {
      dataIndex: "id",
      key: "actions",
      width: 64,
      render: id => <Link to={`./${id}`} onClick={() => trackEvent("click", "return list", "open return detail")}>
        <Button type="text" icon={<ChevronDownIcon style={{ color: "var(--primary-color)", transform: "rotate(-90deg)" }} />} />
      </Link>,
    },
  ].filter(c => !c.hide);

  if (isError) {
    console.log(error);
    return <span>{t("Error...")}</span>;
  }

  return (
    <Responsive.Space direction="vertical">
      <NoStoreAlert />
      <ListLayout sticky {...listHighlight?.props}>
        <InfiniteScroll
          style={{ overflow: "initial" }}
          dataLength={data?.pages.flatMap((group, i) => group.results).length || 0}
          next={() => fetchNextPage()}
          hasMore={isLoading ? true : hasNextPage}
          loader={<Skeleton title paragraph={false} active />}
        >
          <Table
            sticky
            rowKey="id"
            columns={columns}
            dataSource={data?.pages.flatMap((group, i) => group.results)}
            pagination={false}
            loading={isLoading}
            showSorterTooltip={false}
            components={{
              body: {
                wrapper: !permissions.orders ? () => (
                  <tbody className="ant-table-tbody">
                    <tr className="ant-table-placeholder">
                      <td className="ant-table-cell" colSpan="100">
                        <NoPermissionEmpty subject={t("returns")} includeButton />
                      </td>
                    </tr>
                  </tbody>
                ) : undefined,
              }
            }} />
        </InfiniteScroll>
      </ListLayout>
    </Responsive.Space>
  );
}

export default ReturnList;

