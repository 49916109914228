import PropTypes from "prop-types";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import { useStoreList } from "../client/hooks";

const StoreInfoContext = createContext(null);

export function StoreInfoProvider({ children }) {
  const stores = useStoreList();
  const availableStoreIds = stores.data?.map(s => s.id) || [];
  const { storeIds } = useParams();
  const storeIdArray = storeIds.split("+");

  const selectedStoresExist = stores.isLoading || storeIds === "all" || storeIds.split("+").every(id => availableStoreIds.includes(id));

  let selectedStores = storeIds === "all" ? stores?.data : stores?.data?.filter(store => storeIdArray.includes(store.id));
  selectedStores = Object.fromEntries(selectedStores?.map(store => [store.id, store]) || []);
  let selectedStoreIds = Object.keys(selectedStores);

  const value = {
    storeIdString: storeIds,
    storeIds: storeIdArray,
    stores,
    loadingStores: stores.isLoading,
    availableStoreIds,
    selectedStoreIds,
    selectedStores,
    selectedStoresExist,
  };

  return (
    <StoreInfoContext.Provider value={value}>
      {children}
    </StoreInfoContext.Provider>
  );
}

StoreInfoProvider.propTypes = {
  children: PropTypes.node,
};

export function useStoreInfo() {
  return useContext(StoreInfoContext);
}
